import {Injectable} from '@angular/core';
import { ItemChange } from '@progress/kendo-angular-charts/common/collection.service';
import {BehaviorSubject} from 'rxjs';
import { CoreService } from 'src/app/views/core/core.service';
import { IAppModuleModel } from 'src/app/views/refdata/models/i-app-settings.model';
import { AppService } from './app.service';

interface IMenuItem {
  type: string;       // Possible values: link/dropDown/icon/separator/extLink
  name?: string;      // Used as display text for item and title for separator type
  state?: string;     // Router state
  icon?: string;      // Material icon name
  tooltip?: string;   // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
  roles: string[];   // @dherbe added to be able to filter item based on user roles
  module: string;
}

interface IChildItem {
  type?: string;
  name: string;       // Display text
  state?: string;     // Router state
  fullState?: string;
  icon?: string;
  roles?: string[];
  sub?: IChildItem[];
  module?: string;
}

interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

@Injectable()
export class NavigationService {

  selectedItem = 'teste';
  emptytMenu : IMenuItem[] = [
    {
      name: 'Purchasing',
      type: 'dropDown',
      tooltip: 'Purchase',
      icon: 'account_balance_wallet',
      state: '',
      roles : ['Invalid-Role-System'],
      module : 'Invalid-Module-System'
  }];

  defaultMenu: IMenuItem[] = [
    
    {
      type: 'dropDown',
      icon: 'flight_takeoff',
      name: "My Work - Validator",
      state: "trading/mywork",
      //roles: ['SuperUser', 'Approver', 'Validator'],
      roles: ['Validator'],
      module: "trading",
      sub: [
        {
          type: 'dropDown',
          name: "Buy",
          //state: 'buy/dashboard',
          state: 'trading/buy/dashboard/mywork',
          icon: 'add_shopping_cart',
          roles : ['Validator'],
          module: "trading",
          sub: [
            {
              type: 'link',
              name: "Pending",
              state: 'pending',
              fullState: 'trading/buy/dashboard/pending/mywork',
              icon: 'history',
              roles : ['Validator'],
              module: "trading"
            },
            {
              type: 'link',
              name: "For approval",
              state: 'approval',
              fullState: 'trading/buy/dashboard/approval/mywork',
              icon: 'hourglass_empty',
              roles : ['Validator'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Approved",
              state: 'approved',
              fullState: 'trading/buy/dashboard/approved/mywork',
              icon: 'check',
              roles : ['Validator'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Archived",
              state: 'archived',
              fullState: 'trading/buy/dashboard/archived/mywork',
              icon: 'folder_open',
              roles : ['Validator'],
              module: "trading"
            }
          ]
        },
        {
          type: 'dropDown',
          name: "Sell",
          state: 'trading/sell/dashboard/mywork',
          icon: 'sell',
          roles : ['Validator'],
          module: "trading",
          sub: [
            {
              type: 'link',
              name: "Pending",
              state: 'pending',
              fullState: 'trading/sell/dashboard/pending/mywork',
              icon: 'history',
              roles : ['Validator'],
              module: "trading"
            },
            {
              type: 'link',
              name: "For approval",
              state: 'approval',
              fullState: 'trading/sell/dashboard/approval/mywork',
              icon: 'hourglass_empty',
              roles : ['Validator'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Approved",
              state: 'approved',
              fullState: 'trading/sell/dashboard/approved/mywork',
              icon: 'check',
              roles : ['Validator'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Archived",
              state: 'archived',
              fullState: 'trading/sell/dashboard/archived/mywork',
              icon: 'folder_open',
              roles : ['Validator'],
              module: "trading"
            }
          ]
        },
      ]
    },
    {
      type: 'dropDown',
      icon: 'flight_takeoff',
      name: "My Work - Approver",
      state: "trading/mywork/approver",
      //roles: ['SuperUser', 'Approver', 'Validator'],
      roles: ['Approver'],
      module: "trading",
      sub: [
        {
          type: 'dropDown',
          name: "Buy",
          //state: 'buy/dashboard',
          state: 'trading/buy/dashboard/pending/mywork/approver',
          //state: 'trading/buy/dashboard/mywork',
          icon: 'add_shopping_cart',
          roles : ['Approver'],
          module: "trading",
          sub: [
            {
              type: 'link',
              name: "Pending",
              state: 'pending',
              fullState: 'trading/buy/dashboard/pending/mywork/approver',
              //fullState: 'trading/buy/dashboard/pending/mywork',
              icon: 'history',
              roles : ['Approver'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Rejected",
              state: 'approval',
              fullState: 'trading/buy/dashboard/rejected/mywork/approver',
              //fullState: 'trading/buy/dashboard/rejected/mywork',
              icon: 'warning',
              roles : ['Approver'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Approved",
              state: 'approved',
              fullState: 'trading/buy/dashboard/approved/mywork/approver',
              //fullState: 'trading/buy/dashboard/approved/mywork',
              icon: 'check',
              roles : ['Approver'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Archived",
              state: 'archived',
              fullState: 'trading/buy/dashboard/archived/mywork/approver',
              //fullState: 'trading/buy/dashboard/archived/mywork',
              icon: 'folder_open',
              roles : ['Approver'],
              module: "trading"
            }
          ]
        },
        {
          type: 'dropDown',
          name: "Sell",
          state: 'trading/sell/dashboard/pending/mywork/approver',
          //state: 'trading/sell/dashboard/mywork',
          icon: 'sell',
          roles : ['Approver'],
          module: "trading",
          sub: [
            {
              type: 'link',
              name: "Pending",
              state: 'pending',
              fullState: 'trading/sell/dashboard/pending/mywork/approver',
              //fullState: 'trading/sell/dashboard/pending/mywork',
              icon: 'history',
              roles : ['Approver'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Rejected",
              state: 'approval',
              fullState: 'trading/sell/dashboard/rejected/mywork/approver',
              //fullState: 'trading/sell/dashboard/rejected/mywork',
              icon: 'warning',
              roles : ['Approver'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Approved",
              state: 'approved',
              fullState: 'trading/sell/dashboard/approved/mywork/approver',
              //fullState: 'trading/sell/dashboard/approved/mywork',
              icon: 'check',
              roles : ['Approver'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Archived",
              state: 'archived',
              fullState: 'trading/sell/dashboard/archived/mywork/approver',
              //fullState: 'trading/sell/dashboard/archived/mywork',
              icon: 'folder_open',
              roles : ['Approver'],
              module: "trading"
            }
          ]
        },
      ]
    },
    {
      type: 'link',
      icon: 'flight_takeoff',
      name: "My Work",
      state: "trading/mywork",
      roles: ['SuperUser'],
      module: "mywork"
    },
    {
      name: 'Purchasing',
      type: 'dropDown',
      tooltip: 'Purchase',
      icon: 'account_balance_wallet',
      state: 'sar/sar-dashboard',
      roles : ['Buyer','Manager','Director','Admin', 'Requestor', 'Chairman', 'VicePresident','Observer'],
      sub: [
        {
          type: 'link',
          name: 'My Activity',
          state: 'dashboard',
          fullState: 'dashboard',
          icon: 'bubble_chart',
          roles : ['Buyer','Manager','Director','Admin','Requestor','Observer']
        },
        {
          type: 'link',
          name: "Purchase Requests",
          state: 'sar/sar-dashboard',
          fullState: 'sar/sar-dashboard',
          icon: 'local_offer',
          roles: ['Buyer','Manager','Director','Admin', 'Requestor', 'Chairman', 'VicePresident','Observer']
        },
        {
          type: 'link',
          icon: 'local_grocery_store',
          name: "Purchase orders",
          state: 'purchase-orders',
          fullState: 'purchase-orders',
          roles: ['Buyer','Manager','Director','Admin', 'Chairman', 'VicePresident','Observer']
        },
        {
          type: 'link',
          name: "Material Requirements",
          state: 'sar/msps-mrp',
          fullState: 'sar/mps-mrp',
          icon: 'analytics',
          roles: ['Admin']
        },
        {
          type: 'link',
          name: "Goods Receipt",
          state: 'sar/goods-receipt',
          fullState: 'sar/goods-receipt',
          icon: 'receipt_long',
          roles: ['Admin']
        },
        {
          type: 'link',
          icon: 'article',
          name: "Documentation",
          fullState: 'sar/documentation',
          roles: ['SuperUser']
        },
        
        // {
        //   type: 'link',
        //   name: "Receptions",
        //   state: 'sar/sar-receptions',
        //   fullState: 'sar/sar-receptions',
        //   icon: 'layers',
        //   roles: ['Buyer','Manager','Director','Admin', 'Requestor', 'Chairman', 'VicePresident']
        // },


      ],
      module: "purchase-requests"
    },
    
    {
      name: 'Sales',
      type: 'dropDown',
      tooltip: 'Sales',
      icon: 'store',
      state: 'sales/dashboard',
      roles : ['Buyer','Manager','Director','Admin', 'Requestor', 'Chairman', 'VicePresident'],
      sub: [
        {
          type: 'link', 
          icon: 'store',
          name: "Sales",
          state: "sales/sales-sar-dashboard",
          fullState: 'sales/sales-sar-dashboard',
          roles: ['SuperUser', 'Admin', 'Planner', 'Evaluator', 'Approver'],
        },
      ],
      module: "sales"
    },
    {
      type: 'dropDown',
      icon: 'remove_red_eye',
      name: "All Trades",
      state: "trading/overview",
      //roles: ['SuperUser', 'Trader', 'Supervisor'],
      roles: ['Admin', 'SuperUser'],
      module: "trading",
      sub: [
        {
          type: 'dropDown',
          name: "Buy",
          state: 'buy',
          fullState: 'trading/overview/buy',
          //fullState: 'trading/buy/dashboard/pending/mywork',
          icon: 'add_shopping_cart',
          roles : ['Admin', 'SuperUser'],
          module: "trading",
          sub: [
            {
              type: 'link',
              name: "Drafts",
              state: 'drafts',
              fullState: 'trading/overview/buy/drafts',
              //fullState: 'trading/sell/dashboard/pending/mywork',
              roles : ['Admin', 'SuperUser'],
              module: "trading"
            },
            {
              type: 'link',
              name: "In Confirmation",
              state: 'confirmation',
              fullState: 'trading/overview/buy/confirmation',
              //fullState: 'trading/sell/dashboard/rejected/mywork',
              roles : ['Admin', 'SuperUser'],
              module: "trading"
            },
            {
              type: 'link',
              name: "In Validation",
              state: 'validation',
              fullState: 'trading/overview/buy/validation',
              //fullState: 'trading/sell/dashboard/approved/mywork',
              roles : ['Admin', 'SuperUser'],
              module: "trading"
            },
            {
              type: 'link',
              name: "In Approval",
              state: 'approval',
              fullState: 'trading/overview/buy/approval',
              //fullState: 'trading/sell/dashboard/archived/mywork',
              roles : ['Admin', 'SuperUser'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Approved",
              state: 'approved',
              fullState: 'trading/overview/buy/approved',
              //fullState: 'trading/sell/dashboard/archived/mywork',
              roles : ['Admin', 'SuperUser'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Archived",
              state: 'archived',
              fullState: 'trading/overview/buy/archived',
              //fullState: 'trading/sell/dashboard/archived/mywork',
              roles : ['Admin', 'SuperUser'],
              module: "trading"
            }
          ]
        },
        {
          type: 'dropDown',
          name: "Sell",
          state: 'sell',
          fullState: 'trading/overview/sell',
          //fullState: 'trading/buy/dashboard/pending/mywork',
          icon: 'sell',
          roles : ['Admin', 'SuperUser'],
          module: "trading",
          sub: [
            {
              type: 'link',
              name: "Drafts",
              state: 'drafts',
              fullState: 'trading/overview/sell/drafts',
              //fullState: 'trading/sell/dashboard/pending/mywork',
              roles : ['Admin', 'SuperUser'],
              module: "trading"
            },
            {
              type: 'link',
              name: "In Confirmation",
              state: 'confirmation',
              fullState: 'trading/overview/sell/confirmation',
              //fullState: 'trading/sell/dashboard/rejected/mywork',
              roles : ['Admin', 'SuperUser'],
              module: "trading"
            },
            {
              type: 'link',
              name: "In Validation",
              state: 'validation',
              fullState: 'trading/overview/sell/validation',
              //fullState: 'trading/sell/dashboard/approved/mywork',
              roles : ['Admin', 'SuperUser'],
              module: "trading"
            },
            {
              type: 'link',
              name: "In Approval",
              state: 'approval',
              fullState: 'trading/overview/sell/approval',
              //fullState: 'trading/sell/dashboard/archived/mywork',
              roles : ['Admin', 'SuperUser'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Approved",
              state: 'approved',
              fullState: 'trading/overview/sell/approved',
              //fullState: 'trading/sell/dashboard/archived/mywork',
              roles : ['Admin', 'SuperUser'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Archived",
              state: 'archived',
              fullState: 'trading/overview/sell/archived',
              //fullState: 'trading/sell/dashboard/archived/mywork',
              roles : ['Admin', 'SuperUser'],
              module: "trading"
            }
          ]
        },
      ]
    },
    
    {
      type: 'dropDown',
      icon: 'attach_money',
      name: "My Trades",
      state: "trading",
      //state: "trading/dashboard",
      //roles: ['SuperUser', 'Trader', 'Supervisor'],
      roles: ['Trader', 'Supervisor'],
      module: "trading",
      sub: [
        {
          type: 'dropDown',
          name: "Buy",
          //state: 'buy/dashboard',
          state: 'trading/buy/dashboard',
          icon: 'add_shopping_cart',
          roles : ['Trader', 'Supervisor'],
          module: "trading",
          sub: [
            {
              type: 'link',
              name: "Pending",
              state: 'pending',
              fullState: 'trading/buy/dashboard/pending',
              icon: 'history',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            },
            /*
            {
              type: 'link',
              name: "Submitted",
              state: 'confirmation',
              fullState: 'trading/buy/dashboard/confirmation',
              icon: 'redo',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            },*/
            {
              type: 'link',
              name: "Sent for confirmation",
              state: 'confirmation',
              fullState: 'trading/buy/dashboard/confirmation',
              icon: 'arrow_forward',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Under validation",
              state: 'validation',
              fullState: 'trading/buy/dashboard/validation',
              icon: 'arrow_forward',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            },
            {
              type: 'link',
              name: "For approval",
              state: 'approval',
              fullState: 'trading/buy/dashboard/approval',
              icon: 'arrow_forward',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Approved",
              state: 'approved',
              fullState: 'trading/buy/dashboard/approved',
              icon: 'check',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Archived",
              state: 'archived',
              fullState: 'trading/buy/dashboard/archived',
              icon: 'folder_open',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            }
          ]
        },
        {
          type: 'dropDown',
          name: "Sell",
          state: 'trading/sell/dashboard',
          icon: 'sell',
          roles : ['Trader', 'Supervisor'],
          module: "trading",
          sub: [
            {
              type: 'link',
              name: "Pending",
              state: 'pending',
              fullState: 'trading/sell/dashboard/pending',
              icon: 'history',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            },
            /*
            {
              type: 'link',
              name: "Submitted",
              state: 'confirmation',
              fullState: 'trading/sell/dashboard/confirmation',
              icon: 'redo',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            },*/
            {
              type: 'link',
              name: "Sent for confirmation",
              state: 'confirmation',
              fullState: 'trading/sell/dashboard/confirmation',
              icon: 'arrow_forward',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Under validation",
              state: 'validation',
              fullState: 'trading/sell/dashboard/validation',
              icon: 'arrow_forward',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            },
            {
              type: 'link',
              name: "For approval",
              state: 'approval',
              fullState: 'trading/sell/dashboard/approval',
              icon: 'arrow_forward',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Approved",
              state: 'approved',
              fullState: 'trading/sell/dashboard/approved',
              icon: 'check',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            },
            {
              type: 'link',
              name: "Archived",
              state: 'archived',
              fullState: 'trading/sell/dashboard/archived',
              icon: 'folder_open',
              roles : ['Trader', 'Supervisor'],
              module: "trading"
            }
          ]
        },
      ]
    },
    {
      type: 'link',
      icon: 'flight_takeoff',
      name: "Logistics",
      state: "logistics/log-dashboard",
      //roles: ['SuperUser', 'Logistics'],
      roles: ['LogisticsLead', 'Logistics'],
      module: "trading"
    },
    
    {
      name: 'Forecast',
      type: 'dropDown',
      tooltip: 'Forecast',
      icon: 'insights',
      state: 'forecast',
      roles : ['SuperUser', 'Admin', 'Planner', 'Evaluator', 'Approver'],
      module: "forecast",
      sub: [
        {
          type: 'link',
          name: "Overview",
          state: 'forecast',
          fullState: 'forecast',
          icon: 'scatter_plot',
          roles : ['SuperUser', 'Admin', 'Planner', 'Evaluator', 'Approver']
        },
        {
          type: 'link',
          name: "Update",
          state: 'forecast/submit',
          fullState: 'forecast/submit',
          icon: 'airline_stops',
          roles : ['SuperUser', 'Admin', 'Planner', 'Evaluator', 'Approver']
        },
        {
          type: 'link',
          name: "Scenarios",
          state: 'forecast/scenarios',
          fullState: 'forecast/scenarios',
          icon: 'precision_manufacturing',
          roles : ['SuperUser', 'Admin', 'Planner', 'Evaluator', 'Approver']
        },
        
        // ,
        // {
        //   type: 'link',
        //   name: 'Settings',
        //   state: 'forecast/settings',
        //   fullState: 'forecast/settings',
        //   icon: 'bar_chart',
        //   roles : ['SuperUser', 'Admin', 'Evaluator']
        // }
      ]
    },
    {
      name: 'Manage',
      type: 'dropDown',
      tooltip: 'Admin',
      icon: 'settings_suggest',
      state: 'user/user-dashboard',
      roles : ['Admin', 'SuperUser'],
      sub: [
        {
          type: 'link',
          name: 'Departments',
          //state: 'administration/department-settings',
          //fullState: 'administration/department-settings',
          state: 'structure/list',
          fullState: 'structure/list',
          icon: 'account_tree',
          roles : ['Admin'],
          module: "purchase-requests"
        },
        {
          name: 'Suppliers',
          type: 'link',
          icon: 'face',
          fullState: 'structure/supplier',
          roles : ['SuperUser', 'Admin', 'Planner', 'Evaluator', 'Approver'],
          module: "purchase-requests"
        },
        {
          name: 'Materials',
          type: 'link',
          icon: 'factory',
          fullState: 'structure/materials',
          roles : ['SuperUser', 'Admin', 'Planner', 'Evaluator', 'Approver'],
          module: "purchase-requests"
        },
        {
          name: 'Workflows',
          type: 'link',
          icon: 'device_hub',
          //fullState: 'administration/workflows',
          fullState: 'structure/workflows',
          roles : ['SuperUser', 'Admin'],
          module: "purchase-requests"
        },
        {
          name: "Lists and master data",
          type: "dropdown",
          fullState: "refdata/lists", 
          icon: 'list_alt',
          roles : ['SuperUser', 'Admin', 'Planner', 'Evaluator', 'Approver'],
          //module: "purchase-requests"
        },
        {
          name: 'Schema',
          type: 'link',
          icon: 'equalizer',
          //fullState: 'administration/workflows',
          fullState: 'structure/schema',
          roles : ['Admin'],
          module: "purchase-requests"
        },
        {
          type: 'link',
          name: "Users",
          state: 'user/user-dashboard',
          fullState: 'user/user-dashboard',
          icon: 'group',
          roles : ['Admin']
        },
        
      ],

      module: "admin"
    },

    {
      name: 'System',
      type: 'dropDown',
      tooltip: 'Admin',
      icon: 'manage_accounts',
      state: 'user/user-dashboard',
      roles : ['Admin', 'SuperUser'],
      sub: [
        {
          type: 'link',
          name: 'Settings',
          state: 'settings',
          fullState: 'settings',
          icon: 'build',
          roles : ['SuperUser', 'Admin']
        },
        {
          type: 'link',
          name: 'Event viewer',
          state: 'admin/logging',
          fullState: 'admin/logging',
          icon: 'clear_all',
          roles : ['SuperUser', 'Admin']
        },
        {
          type: 'link',
          name: 'Jobs',
          state: 'admin/scheduler',
          fullState: 'admin/scheduler',
          icon: 'timer',
          roles : ['SuperUser', 'Admin']
        }
        
      ],
      module: "admin"
    },
    
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems;
  // navigation component has subscribed to this Observable
  menuItems$;

  
 
  availableModules = [];
  constructor(private appService: AppService, private coreService: CoreService) {
      this.filterMenuByAvailableModules();  
  }

  bindMenus(){ 
    this.filterMenuByAvailableModules();
  }

  private filterMenuByAvailableModules(){
    const availableModules: IAppModuleModel[] = this.appService.getAvailableModules();
    if (availableModules && availableModules.length){
      this.defaultMenu = this.defaultMenu.filter(menu => 
        availableModules.some(module => 
          module.module == menu.module && (module.settings == null || module.settings?.disabledMenus == null || !module.settings?.disabledMenus?.some(state => state == menu.state))
        )
      );
      this.defaultMenu.forEach(menu => {
        if(menu.sub?.length > 0){
          menu.sub = menu.sub.filter(childMenu => 
            availableModules.some(module => 
              (!childMenu.module) || (module.module == childMenu.module && (module.settings == null || module.settings?.disabledMenus == null || !module.settings?.disabledMenus?.some(state => state == childMenu.state)))
            )
          );
        }
      })
    }
  }
userMenu : IMenuItem[];
  updateMenu(menuItems: IMenuItem[]){
  this.userMenu = menuItems;
    if(menuItems.length == 0) 
    {
      this.menuItems = new BehaviorSubject<IMenuItem[]>(this.emptytMenu);
    this.menuItems$ = this.menuItems.asObservable();
    return;
    }
    if(this.menuItems$){
      try{
        this.menuItems$.unsubscribe();
        this.menuItems$ =null;
      }catch(ex){}
     
    }
    this.menuItems = new BehaviorSubject<IMenuItem[]>(menuItems);
    this.menuItems$ = this.menuItems.asObservable();
  }

  resetMenu(){
    this.updateMenu(this.emptytMenu);
  }
  

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    this.menuItems.next(this.defaultMenu);
  }
}
