import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfig } from 'src/app/app-config.module'
import { HttpUtils } from '../core/utils/http.utils';
import { ISarModel } from '../sar/models/i-sar.model';
import { ITradeApprovalRequest } from '../sar/models/sap/i-trade-approval-model';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class TradingService {

  public readonly PURCHASE_ORDER_DOC_TYPE: string = "PurchaseOrder";
  public readonly SALES_CONTRACT_DOC_TYPE: string = "SalesContract";
  public readonly SALES_ORDER_DOC_TYPE: string = "SalesOrder"; 
  public readonly CERTIFICATE_OF_ANALYSIS_TYPE: string = "CertificateOfAnalysis";
  public readonly GUARANTEED_SALES_SPEC_TYPE: string = "GuaranteedSalesSpecifications";
  public readonly PICKING_NOTE_TYPE: string = "PickingNote";
  public readonly SALES_ORDER_CONF_TYPE: string = "SalesOrderConfirmation";
  public componentsStore: any[] = [];
  public isSell: boolean = false;
  public currentStatusId: number;
  public selectedMat:any[]=[];
  public currentBatchPropertiesData: any[] = [];
  private deletedItems: any[] = [];
  private customers: any[] = [];
  private cities: any[] = [];
  private countries: any[] = [];
  private salesOrgs: any[] = [];
  private bankAccounts: any[] = [];
  private salesOffices: any[] = [];
  private incoterms: any[] = [];
  private paymentTerms: any[] = [];
  private purchasingGroups: any[] = [];
  private suppliers: any[] = [];
  private exchangeRates: any[] = [];
  private traders: any[] = [];
  private supervisors: any[] = [];
  private logistics: any[] = [];
  private approvers: any[] = [];
  private validators: any[] = [];
  private companyCodes: any[] = [];
  private docOptions: any[] = [];
  private tradeSpecialRequests: any[] = [];
  private previousAction: string = "";
  private materials: any[] = [];
  private unitMeasures: any[] = [];
  private qualityOptions: any[] = [];
  private customsStatus1: any[] = [];
  private deliveryDateOptions: any[] = [];
  private documentOptions: any[] = [];
  private data: any = {};
  private isBuy = true;
  private backTo = "";
  
  constructor(private http: HttpClient, private config: AppConfig, private userService: UserService) { }

  /*
  getReportBySarId<T>(sarId: number, docType: string): Observable<Blob> {
    const url = this.config.getConfig('apiEndpoint') + 'trade/downloadReport/' + sarId + '/' + docType;
    return this.http.get(url, { responseType: 'blob' });
  }
  */

  
  getReportByDocType<T>(sapDocumentId: string, docType: string, sarId?: number): Observable<Blob>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/downloadReport/' + sapDocumentId + '/' + sarId + '/' + docType;
    return this.http.get(url, { responseType: 'blob' });
  }

  getReportBySAPDocumentId<T>(sapDocumentId: string, sarId?: number): Observable<Blob>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/downloadReport/' + sapDocumentId + '/' + sarId;
    return this.http.get(url, { responseType: 'blob' });
  }

  getReport<T>(id: number, docType: string, batchNumber?: string): Observable<Blob> {
    const url = this.config.getConfig('apiEndpoint') + 'trade/download/' + id + '/' + docType + '/' + batchNumber;
    return this.http.get(url, { responseType: 'blob' });
  }

  sendEmail<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/email/' + sarId;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getCreditLimit<T>(supplierCode: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/limit/' + supplierCode;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getUsersByRole<T>(role: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `users/role/${role}/name`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getActiveUsersByRole<T>(role: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `users/role/${role}/name/active`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }
  

  getTextTypes<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'textTypes';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getBuyTextTypes<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `textTypes/buy`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getSellTextTypes<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `textTypes/sell`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getBuyContractTextTypes<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `textTypes/buy/contract`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  addMaterialAssociation<T>(req:any): Observable<T> {
    return this.http.post<T>(this.config.getConfig('apiEndpoint') + 'serviceAcc', req)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getCustomers<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'customer';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getUIComponents<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `ux`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getUIComponentIdByName(component: string, moduleName: string): any {
    if(this.componentsStore.length <= 0){
      this.getUIComponents().subscribe((res:any[])=>{
        if(res){
          this.componentsStore=res;
          localStorage.setItem("uxComponents", JSON.stringify(this.componentsStore));
          let data = this.componentsStore?.find(val => val.component == component && val.moduleName==moduleName);
          return data?.id;
        }
      });
    }else{
      let data = this.componentsStore?.find(val => val.component == component && val.moduleName==moduleName);
      return data?.id;
    }
  }

  getgoodsIssue<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `trade/issue/${sarId}`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getGoodsIssued<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `trade/issued/${sarId}`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getSalesOffices<T>(salesOrgId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `sales/org/${salesOrgId}/office`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getDefaultLogisticFH<T>(materialCode: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `material/handler/${materialCode}`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getStockByMaterial<T>(materialCode: string, plantCode: string, locationCode: string): Observable<T>{
    var filter = "";
    //filter = "&filter=";
    if(plantCode || locationCode){
      filter = "&filter=";
    }
    if(plantCode){
      filter += "Plant eq '" + plantCode + "'";
    }
    if(plantCode && locationCode){
      filter += "&";
    }
    if(locationCode && !plantCode){
      filter += "&StorageLocation eq '" +  locationCode + "'";
    }
    //const url = this.config.getConfig('apiEndpoint') + `material/${materialCode}/${plantCode}/${locationCode}`;
    //const url = this.config.getConfig('apiEndpoint') + `material/${materialCode}/stock${filter}`;
    const url = this.config.getConfig('apiEndpoint') + `material/${materialCode}/stock`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getStockByMaterialAndQueryString<T>(materialCode: string, plantCode: string, locationCode: string): Observable<T>{
    var filter = "";
    //filter = "&filter=";
    if(plantCode || locationCode){
      filter = "&filter=";
    }
    if(plantCode){
      filter += "Plant eq '" + plantCode + "'";
    }
    if(plantCode && locationCode){
      filter += "&";
    }
    if(locationCode && !plantCode){
      filter += "&StorageLocation eq '" +  locationCode + "'";
    }
    //const url = this.config.getConfig('apiEndpoint') + `material/${materialCode}/${plantCode}/${locationCode}`;
    //const url = this.config.getConfig('apiEndpoint') + `material/${materialCode}/stock${filter}`;
    const url = this.config.getConfig('apiEndpoint') + `material/stock/?materialCode=${materialCode}`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getCitiesByCountryId<T>(countryId: number): Observable<T>{
    //const url = this.config.getConfig('apiEndpoint') + `cityByCountry/${countryId}`;
    const url = this.config.getConfig('apiEndpoint') + `country/${countryId}/cities`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getAllPlants<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `trade/plants`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getAllLocations<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `trade/locations`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  notifyAll<T>(sarId: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `trade/notify/${sarId}`;
    return this.http.post<T>(url, {})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getBpFunctionsByFunctionCode<T>(functionCode: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `businessPartner/${functionCode}`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getBpFunctionsByBpCodeAndFunctionCode<T>(functionCode: string, bpCode: string, isSupplier: boolean): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `businessPartner/${functionCode}/bpCode/${bpCode}/${isSupplier}`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getBusinessPartnersByCustomerCodes(body: any): Observable<any>{
    const url = this.config.getConfig('apiEndpoint') + `businessPartner/bp/customerCodes`;
    return this.http.patch(url, body)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  createPurchaseOrderInSAP<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `purchase-orders`;
    return this.http.put<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  updatePurchaseOrderInSAP<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `purchase-orders/UpdatePurchaseOrder`;
    return this.http.patch<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  createSalesOrderInSAP<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `sales-orders`;
    return this.http.put<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  updateSalesOrderInSAP<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `sales-orders/UpdateSalesOrder`;
    return this.http.patch<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  triggerSignedApprovalReport<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `sars/${sarId}/document/sign`;
    return this.http.post<T>(url, {})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getBankAccountsBySalesOrg<T>(salesOrgCode: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `bankAccount/${salesOrgCode}/salesOrg`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getProgressionToApprovalValidation<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `trade/submitForApprovalConfirmation/${sarId}`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }
  
  getCountryByCityId<T>(cityId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `country/city/${cityId}`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  createPurchaseContractInSAP<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `purchase-contracts`;
    return this.http.put<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  updatePurchaseContractInSAP<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `purchase-contracts/UpdatePurchaseContract`;
    return this.http.patch<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public createSalesContractInSAP<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `sales-contracts`;
    return this.http.put<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public updateSalesContractInSAP<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `sales-contracts/UpdateSalesContract`;
    return this.http.patch<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public createGoodsReceipt<T>(sarId: number, properties: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `goods-receipt`;
    return this.http.put<T>(url, {sarId: sarId, grItemProperties: properties})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public createGoodsIssue<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `goods-issue`;
    return this.http.put<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public createServicePurchaseOrder<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `purchase-orders/service`;
    return this.http.post<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public createServiceGoodsReceipt<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `goods-receipt/service`;
    return this.http.put<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public createGoodsIssueServicePurchaseOrder<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `purchase-orders/service/goods-issue`;
    return this.http.post<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public updateServicePurchaseOrder<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `purchase-orders/service/UpdateServicePurchaseOrder`;
    return this.http.patch<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public createBatchCharacteristic<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `coa`;
    return this.http.post<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public createProcessOrder<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `procesOrder`;
    return this.http.put<T>(url, {sarId: sarId})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public getAvailableQuantities<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `trade/calls/available/` + sarId;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public getServiceAccBySarId<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `serviceAcc/services/` + sarId;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public getVersion<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/version' ;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public getTradeStatusFlowBySarId<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/statusFlow/' + sarId ;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public getArchivedTrades<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/archived';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public getTradeByStatusId<T>(statusId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/status/' + statusId ;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public getServiceIcons<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/serviceIcons';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public getIcons<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/icons';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public deleteTrade<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/' + sarId;
    return this.http.delete<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public deleteSalesOrderItem<T>(deletedItems: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'sales-orders/items';
    return this.http.patch<T>(url, deletedItems)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public deleteSalesContractItem<T>(deletedItems: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'sales-contracts/items';
    return this.http.patch<T>(url, deletedItems)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public getAvailablePlantsByMaterial<T>(materialCode: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'material/' + materialCode + '/plants';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public generateInvoice<T>(documentNr: any, sarItemId: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'goods-issue/invoicing/' + documentNr + "/" + sarItemId;
    return this.http.patch<T>(url, {})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  public getUnitMeasuresByMaterialCode<T>(materialCode: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'material/' + materialCode + '/units';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }
  

  //TODO: TO BE REPLACED BY API CALL
  getMaterialMinimumPercentage(): number{
    return 0.95;
  }

  /**
   * Fetch the available components for the current app instance
   */
  public fetchComponents(){
    this.getUIComponents().subscribe((res:any[])=>{
      this.componentsStore = res;
      localStorage.setItem("uxComponents", JSON.stringify(this.componentsStore));
    });
  }

  public hasUxComponents(): boolean{
    return this.componentsStore.length > 0;
  }

  public setUxComponents(uxComponents: any[]): void{
    if(uxComponents != null){
      this.componentsStore = uxComponents;
    }
  }

  public getDeletedItems(): any[] {
    return this.deletedItems;
  }
  
  public setDeletedItems(newItems: any[]): void{
    this.deletedItems = newItems;
  }

  public addDeletedItem(newItem: any): void{
    if(newItem){
      this.deletedItems.push(newItem);
    }
  }

  public isPurchaseContract(dataItem: any): boolean{
    if(dataItem){
      return dataItem?.tradeDetails?.isContract && (dataItem?.statusId == 1);
    }
    return false;
  }

  public isPurchaseOrder(dataItem: any): boolean{
    if(dataItem){
      return !dataItem?.tradeDetails?.isContract && (dataItem?.statusId == 1);
    }
    return false;
  }

  public isSalesContract(dataItem: any): boolean{
    if(dataItem){
      return dataItem?.tradeDetails?.isContract && (dataItem?.statusId == 2);
    }
    return false;
  }

  public isSalesOrder(dataItem: any): boolean{
    if(dataItem){
      return !dataItem?.tradeDetails?.isContract && (dataItem?.statusId == 2);
    }
    return false;
  }

  public setLocalCustomersData(customers: any[]){
    this.customers = customers;
  }

  public getLocalCustomersData(){
    return this.customers;
  }

  public setCitiesData(cities: any[]){
    this.cities = cities;
  }

  public getCitiesData(){
    return this.cities;
  }

  public setCountriesData(countries: any[]){
    this.countries = countries;
  }

  public getCountriesData(){
    return this.countries;
  }

  public setSalesOrgsData(salesOrgs: any[]){
    this.salesOrgs = salesOrgs;
  }

  public getSalesOrgsData(){
    return this.salesOrgs;
  }

  public setBankAccountsData(banckAccounts: any[]){
    this.bankAccounts = this.bankAccounts;
  }

  public getBankAccountsData(){
    return this.bankAccounts;
  }

  public setSalesOfficesData(salesOffices: any[]){
    this.salesOffices = salesOffices;
  }

  public getSalesOfficesData(){
    return this.salesOffices;
  }

  public setIncotermsData(incoterms: any[]){
    this.incoterms = incoterms;
  }

  public getIncotermsData(){
    return this.incoterms;
  }

  public setPaymentTermsData(paymentTerms: any[]){
    this.paymentTerms = paymentTerms;
  }

  public getPaymentTermsData(){
    return this.paymentTerms;
  }

  public setPurchasingGroups(purchasingGroups: any[]){
    this.purchasingGroups = purchasingGroups;
  }

  public getPurchasingGroups(){
    return this.purchasingGroups;
  }

  public setSuppliers(suppliers: any[]){
    this.suppliers = suppliers;
  }

  public getSuppliers(){
    return this.suppliers;
  }

  public setExchangeRates(exchangeRates: any[]){
    this.exchangeRates = exchangeRates;
  }

  public getExchangeRates(){
    return this.exchangeRates;
  }

  public setTraders(traders: any[]){
    this.traders = traders;
  }

  public getTraders(){
    return this.traders;
  }

  public setSupervisors(supervisors: any[]){
    this.supervisors = supervisors;
  }

  public getSupervisors(){
    return this.supervisors;
  }

  public setLogistics(logistics: any[]){
    this.logistics = logistics;
  }

  public getLogistics(){
    return this.logistics;
  }

  public setApprovers(approvers: any[]){
    this.approvers = approvers;
  }

  public getApprovers(){
    return this.approvers;
  }

  public setValidators(validators: any[]){
    this.validators = validators;
  }

  public getValidators(){
    return this.validators;
  }

  public setCompanyCodes(companyCodes: any[]){
    this.companyCodes = companyCodes;
  }

  public getCompanyCodes(){
    return this.companyCodes;
  }

  public setDocOptions(docOptions: any[]){
    this.docOptions = docOptions;
  }

  public getDocOptions(){
    return this.docOptions;
  }

  public setTradeSpecialRequests(tradeSpecialRequests: any[]){
    return this.tradeSpecialRequests = tradeSpecialRequests;
  }

  public getTradeSpecialRequests(){
    return this.tradeSpecialRequests;
  }

  public setPreviousAction(previousAction: string){
    this.previousAction = previousAction;
  }

  public getPreviousAction(){
    return this.previousAction;
  }

  public setMaterials(materials: any[]){
    this.materials = materials;
  }

  public getMaterials(){
    return this.materials;
  }

  public setUnitMeasures(unitMeasures: any[]){
    this.unitMeasures = unitMeasures;
  }

  public getUnitMeasures(){
    return this.unitMeasures;
  }

  public setQualityOptions(qualityOptions: any[]){
    this.qualityOptions = qualityOptions;
  }

  public getQualityOptions(){
    return this.qualityOptions;
  }

  public setCustomsStatus1(customsStatus1: any[]){
    this.customsStatus1 = customsStatus1;
  }

  public getCustomsStatus1(){
    return this.customsStatus1;
  }

  public setDeliveryDateOptions(deliveryDateOptions: any[]){
    this.deliveryDateOptions = deliveryDateOptions;
  }

  public getDeliveryDateOptions(){
    return this.deliveryDateOptions;
  }

  public setDocumentOptions(documentOptions: any[]){
    this.documentOptions = documentOptions;
  }

  public getDocumentOptions(){
    return this.documentOptions;
  }

  public getData(): any{
    return this.data;
  }

  public setData(data: any){
    this.data = data;
  }

  public setIsBuy(isBuy: boolean){
    this.isBuy = isBuy;
  }

  public getIsBuy(): boolean{
    return this.isBuy;
  }

  public setBackTo(backTo: string){
    this.backTo = backTo;
  }

  public getBackTo(): string{
    return this.backTo;
  }

  setCountryCityRelation<T>(cityId: number, countryId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `country/${countryId}/city/${cityId}`;
    return this.http.post<T>(url, {})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }
  public getCountryCityRelation<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'country/all';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

}
