import { Component, Inject, OnInit } from '@angular/core';
import { SarService } from 'src/app/views/sar/sar.service'
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormatService } from 'src/app/shared/services/format.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { IRefdataModel } from '../../refdata/models/i-refdata.model';
import {select, Store} from '@ngrx/store';
import * as fromSar from '../../refdata/state';
import * as fromRefdata from '../../refdata/state';
import { IMaterialRefdataModel } from '../../refdata/models/i-material-refdata.model';
import { RefdataService } from '../../refdata/refdata.service';
import { ConfirmCancelComponent } from 'src/app/shared/helpers/confirm-cancel/confirm-cancel.component';
import { markFormGroupTouched } from 'src/app/shared/helpers/utils';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AuthService } from '../../user/auth.service';
import { AuthGuard } from 'src/app/shared/services/auth/auth.guard';
import { ConfirmationModalComponent } from '../../refdata/components/confirmation-modal/confirmation-modal.component';
import { WorkflowService } from '../../workflows/workflows.service';
import { IApprovalFlow } from '../../workflows/models/approval-flow.model';
import { FormGroupBuilder } from 'src/app/shared/interfaces/i-form-group-builder';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TradingService } from '../../trading/trading.service';
import { PublicService } from '../public.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

/**
 * Component that deals with the add/edit trade process
 */
@Component({
  selector: 'app-approval-decision',
  templateUrl: './approval-decision.component.html',
  styleUrls: ['./approval-decision.component.css']
})
export class ApprovalDecisionComponent implements OnInit{

  public isApproval: boolean = true;
  public displayMessage: boolean = false;
  public formGroup: FormGroup = new FormGroup({});
  public sarId:number;
  public token:string;
  public status:boolean=false;
  public error: boolean = false;
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private router: Router,
    private publicService: PublicService,
    private sarService: SarService, 
    private translate: TranslateService,
    private tradingService: TradingService
  ) { 
  }


  ngOnInit(): void {
    this.sarId = +this.router.url.split("/")[4];
    this.token = this.router.url.split("/")[5];
    this.generateForm();
    this.isApproval = (this.router.url.includes('approve')) ? true : false;
    if(this.isApproval){
      this.publicService.approveTrade(this.sarId, this.token).subscribe(res=> {
        if(res){
          this.displayMessage = true;
          this.translate.get('An email was sent to all users involved in this trade.').subscribe(res => {
            this.sarService.showSnack(res);
          });
        }else{
          this.error = true;
        }
      }, (err) => {
        this.error = true;
      });
    }else{
      //this.send();
    }
  }

  public generateForm(){
    this.formGroup = new FormGroup({
      reasonsForRejection: new FormControl(null,[Validators.required])
    })
  }

  public send(){
    this.blockUI.start("Rejecting the trade ...");
    if(this.formGroup.valid){
      this.publicService.rejectTrade(this.sarId, this.token, this.formGroup.value).subscribe(res=> {
        this.blockUI.stop();
        if(res){
          this.displayMessage=true;
          this.status=true;
        }else{
          this.error = true;
        }
      }, (err) => {
        this.error = true;
      });
    }
  }
  

}



  
