import { Injectable, Inject } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ISarsGetRequestModel} from './models/wrappers/i-sars.get.request.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpUtils} from '../core/utils/http.utils';
import {ISarPostRequestModel} from './models/wrappers/i-sar.post.request.model';
import {IValidateSarModel} from './models/validate/i-validate-sar-model';
import { ISarCommentModel } from './models/i-sar-comment.model';
import { AppConfig } from 'src/app/app-config.module';
import { ISarModel } from './models/i-sar.model';
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class SarService {
  items;
  private selectedSupplier : any = null;

  public getSelectedSupplier(): any{
    return this.selectedSupplier;
  }

  public setSelectedSupplier(supplier : any):void{
    this.selectedSupplier = supplier; 
  }

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private _snackBar:MatSnackBar
  ) {}


  hideNewMat_Per= new BehaviorSubject(false);
  selectedServiceType=new BehaviorSubject(false);
  isServiceComp=new BehaviorSubject(false);
  cCode=new BehaviorSubject('');

  getDashboardSar<T>(state: number): Observable<T> {
    let url: string = this.config.getConfig('apiEndpoint');

    if (state != null && state != undefined){
      url+= `dashboardSars?state=${state}`
    }else{
      url+= 'dashboardSars';
    }
    // const url = this.config.getConfig('apiEndpoint') + (state != null && state != undefined) ? `dashboardSars?state=${state}` : 'dashboardSars';
    // const url = this.config.getConfig('apiEndpoint') + 'dashboardSars?state=' + 0;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  get<T>(getRequest: ISarsGetRequestModel): Observable<T> {
    return this.http.get<T>(this.config.getConfig('apiEndpoint') + 'sars')
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getSarAttachments<T>(sarId:number): Observable<T> {
    return this.http.get<T>(this.config.getConfig('apiEndpoint') + 'sars'+ `/${sarId}` +'/attachments')
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getById<T>(id: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'sars' + `/${id}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getByIdAndSourcingSession<T>(id: number, sourcingSessionId:number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'sars/sourcingSession' + `/${id}/${sourcingSessionId}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getByItemNo<T>(itemNo: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'sars' + `/itemNo/${itemNo}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getByItemRef<T>(itemRef: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'sars' + `/itemRef/${itemRef}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getPlantByCompany<T>(companyCode: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'trade/plantsByCompany/' + `${companyCode}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getLocationsByPlant<T>(Plantid: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'trade/locationsByPlant/' + `${Plantid}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getMaterialCharacterists<T>(materialId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'material/' + `${materialId}` + '/characteristics';
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getBatches<T>(sarItemId:number,materialId: number, batchNumber: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'characteristic/batch/' + `${sarItemId}/${materialId}/${batchNumber}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  addBatch<T>(req:any): Observable<T> {
    return this.http.post<T>(this.config.getConfig('apiEndpoint') + 'characteristic/batches', req)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getByMaterialDescription(materialId: string): Observable <string> {
    // const url = this.config.getConfig('apiEndpoint') + 'sars' + `/itemRef/${materialId}`;
    // return this.http.get<T>(url)
    //   .pipe(
    //     catchError(HttpUtils.handleHttpClientError)
    //   );~
    return of(
      "teste"
    )
  }

  searchSars<T>(keyword: string): Observable<T> {
    let params = {};
    params['keywords'] = encodeURI(keyword ? keyword : ' ');

    const url = this.config.getConfig('apiEndpoint') + 'sars/search';
    return this.http.get<T>(url, {
      params: params
    }).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getPresentationById<T>(id: number): Observable<T> {
    const url = this.config.apiEndpoint + `/sarPresentations/${id}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getMaxQuantityById<T>(sarid: number,materialId:number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `trade/maximum/${sarid}/${materialId}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getProvisionalSupplier <T>(supplier: number,provisional:number): Observable<T> {
    const url = this.config.apiEndpoint + `/${supplier}/${provisional}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getGoodsReceived <T>(sarId:number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `trade/received/${sarId}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getProvisionalSupplierForTrade<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'supplier/provisional';
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  post<T>(req: ISarPostRequestModel): Observable<T> {
    return this.http.post<T>(this.config.getConfig('apiEndpoint') + 'sars', req)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  createSar<T>(req: ISarModel): Observable<T> {
    return this.http.post<T>(this.config.getConfig('apiEndpoint') + 'sars', req)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  patch<T>(req: ISarPostRequestModel): Observable<T> {
    return this.http.patch<T>(this.config.getConfig('apiEndpoint') + 'sars', req)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  validate<T>(req: IValidateSarModel): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'sars' + `/validate`;
    return this.http.patch<T>(url, req)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  validatePR(body : any) {
    const url = this.config.getConfig('apiEndpoint') + 'sars/update';
    return this.http.patch(url,body)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

// Newly dummyUrl
  patchTrade<T>(req:any): Observable<T> {
    return this.http.patch<T>(this.config.getConfig('apiEndpoint') + 'sars/trade', req)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }



  // canValidate(id: number) {
  //   const url = this.config.getConfig('apiEndpoint') + 'sars' + '/canValidate/' + id;
  //   return this.http.get(url)
  //     .pipe(
  //       catchError(HttpUtils.handleHttpClientError)
  //     );
  // }

  downloadAttachment(filename: string) {
    const url = this.config.getConfig('apiEndpoint') + 'sars' + `/downloadAttachment/${filename}`;
    return this.http.get(url, { responseType: 'arraybuffer' })
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  comment<T>(req: ISarCommentModel): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'sars' + `/comment`;
    return this.http.post<T>(url, req)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getFlow<T>(id: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'sars/flow' + `/${id}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  checkSupplier<T>(sarId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'sars/checkSupplier' + `/${sarId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  updateSupplierCode<T>(quotationId: number, supplierCode: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/updateSupplierCode';
    
    return this.http.post<T>(url, {quotationId, supplierCode}).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getMentions<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/mentions' + `/${sarId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  downloadPO(sarId : number) : Observable<Blob> {
    const url = this.config.getConfig('apiEndpoint') + 'sars/downloadPo/' + sarId;
    return this.http.get(url, { responseType: 'blob' });
  }

  rejectItem(itemId: number, sarId: number, comment:string): Observable<any>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/rejectItem';
    
    return this.http.post<any>(url, {id: itemId, sarId, rejected: true, comment: comment}).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  approveItem(itemId: number, sarId: number): Observable<any>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/rejectItem';
    
    return this.http.post<any>(url, {id: itemId, sarId, rejected: false}).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getLogs(sarId: number): Observable<any[]>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/log/' + sarId;
    return this.http.get<any[]>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  syncErp(sarId: number):Observable<any> {
    const url = this.config.getConfig('apiEndpoint') + 'sars/syncErp/' + sarId;
    return this.http.post<any>(url, null).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  bulkUpload(file: File, settings?: any): Observable<any>{
    let url;
    if (settings){
      url = this.config.getConfig('apiEndpoint') + `sars/import?statusId=${settings.statusId}&firstColumAsSarId=${settings.firstColumnAsSarId}`;
    }else{
      url = this.config.getConfig('apiEndpoint') + `sars/import`;
    }
    
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);

    return this.http.post<any>(url, formData).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  notifyApprover(sarId: number){
    const url = this.config.getConfig('apiEndpoint') + 'sars/notifyApprover/' + sarId;
    return this.http.post<any>(url, null).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  bulkSarUpload(body: any){
    const url = this.config.getConfig('apiEndpoint') + 'sars/upload';
    return this.http.post<any>(url, body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }
  
  notifyBuyers(sarId: number){
    const url = this.config.getConfig('apiEndpoint') + 'sars/notifyBuyers/' + sarId;
    return this.http.post<any>(url, null).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  sharerPO(sarId: number){
    const url = this.config.getConfig('apiEndpoint') + 'sars/sharePo/' + sarId;
    return this.http.get<any>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getFileBrowserToken(sarId: number): Observable<any>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/fileBrowser/token/' + sarId;
    return this.http.get<any>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  validateMany(body : any): Observable<any>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/validateMany';
    
    return this.http.post<any>(url, body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  takeoverItem(body : any): Observable<any>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/takeover';
    
    return this.http.post<any>(url, body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  archiveMultiplePR(body : any): Observable<any>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/archive';
    
    return this.http.post<any>(url, body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  notifyPRBuyers(body : any): Observable<any>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/notifyMany';
    return this.http.post<any>(url, body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  transferDepartment(body : any): Observable<any>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/department/transfer';
    return this.http.post<any>(url, body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  saveSarOptions(body : any): Observable<any>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/options';
    return this.http.patch<any>(url, body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }
  
  //body = array de ids, array de user ids
  //new body: array de [{departmendId, [sarIds]}]
  transferMultiplePR(body : any): Observable<any>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/transfer';
    
    return this.http.post<any>(url, body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  //body = array de id, do comment 
  multiplePRComment(body : any): Observable<any>{
    const url = this.config.getConfig('apiEndpoint') + 'sars/commentMany';
    
    return this.http.post<any>(url, body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getReceptionsSar<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'receptions';
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getUserAcess<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/access';
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getSarPurchaseOrders<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') +  `sars/${sarId}/documents`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  canValidateSar<T>(sarId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `sars/${sarId}/actions`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }
  
  patchUpdate<T>(req: ISarModel): Observable<T> {
    //return this.http.patch<T>(this.config.getConfig('apiEndpoint') + 'sars/update', req)
    return this.http.patch<T>(this.config.getConfig('apiEndpoint') + 'sars', req)
    .pipe(
      catchError(HttpUtils.handleHttpClientError)
      );
    }
    
  discardSar<T>(sarId: number): Observable<T> {
    return this.http.delete<T>(this.config.getConfig('apiEndpoint') + `sars/${sarId}`,)
    .pipe(
      catchError(HttpUtils.handleHttpClientError)
      );
    }
      
  infoMaterialSupplier<T>(materialCode: any, supplierCode : any ): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `refdatas/material/${materialCode}/info?supplierCode=${supplierCode}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  createSupplierBySuggestions<T>(materialCode: any): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `refdatas/material/${materialCode}/suppliers`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }
  requestApproval<T>(body: any): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `sars/group`;
    return this.http.post<T>(url,body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  createPO<T>(body: any): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `purchase-orders/po`;
    return this.http.post<T>(url,body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getLockedFields<T>(sarId : number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `sars/${sarId}/locked`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getSarHistory<T>(sarId : number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `sars/${sarId}/history`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  /**
   * 
   * @returns all the trades
   */
  getTrades<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  /**
   * 
   * @param statusId 
   * @returns the trades with the desired statusId value
   */
  getTradesByStatusId<T>(statusId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/status/' + statusId;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  /**
   * 
   * @returns the trades that were not approved yet
   */
  getNonApprovedTrades<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/nonApproved';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getApprovedTrades<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/approved';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getSubmittedToApprovalTrades<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/approval';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getSubmittedToValidationTrades<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/validation';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getSubmittedTrades<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/submitted';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getCallsByContractId<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/calls/' + sarId;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getServicesByCallId<T>(sarId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/services/' + sarId;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getTradesByComponent<T>(componentId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `trade/behavior/${componentId}`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }


  /**
   * Error Message Function 
   * @param msg 
   */
  showSnack(msg : string) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.duration = 6000;
    config.panelClass = ['mat-toolbar', 'mat-primary'];
    this._snackBar.open(msg, undefined, config);
  } 

    /**
   * Error Message Function 
   * @param msg 
   */
  showErrorSnack(msg : string) {
    let config = new MatSnackBarConfig();
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';
    config.duration = 6000;
    config.panelClass = ['mat-toolbar', 'mat-warn'];
    this._snackBar.open(msg, undefined, config);
  } 
}



/*
displayedColumns: string[] = ['itemNo', 'itemRef', 'previousSarItemRef', 'regionId',
  'locationId', 'totalCost', 'status', 'statusDate', 'pendingOn', 'sourcingSessionDate'];
sars: ISarSummaryModel[] = [{
  id: 123,
  itemNo: '243',
  itemRef: '123',
  previousSarItemRef: '',
  regionId: 'EU',
  locationId: 'teste',
  totalCost: 123,
  status: 'teste',
  statusDate: moment(),
  pendingOn: 'precv',
  sourcingSessionDate: null
},
  {
    id: 124,
    itemNo: '2323',
    itemRef: '23223',
    previousSarItemRef: '',
    regionId: 'JP',
    locationId: 'teste',
    totalCost: 123,
    status: 'teste',
    statusDate: moment(),
    pendingOn: 'precv',
    sourcingSessionDate: null
  }];*/