import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { AppConfig } from 'src/app/app-config.module';
import { HttpUtils } from '../core/utils/http.utils';
import { ICreateCommentModel } from '../forecast/models/create-comment.model';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  constructor(
    private http: HttpClient,
    private config: AppConfig
  ) {
  }

  generateToken<T>(sarId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `auth/token?sarId=${sarId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  validatePR<T>(token: string, statusId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `sars/token/${token}/${statusId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  validateToken<T>(token: string){
    const url = this.config.getConfig('apiEndpoint') + `sars/validateToken/${token}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  customerSatisfactionSurvey<T>(token: string){
    const url = this.config.getConfig('apiEndpoint') + `css/token/${token}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  submitCustomerSatisfactionSurvey<T>(survey: any){
    const url = this.config.getConfig('apiEndpoint') + `css`;
    return this.http.post<T>(url, survey).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  validateForecast<T>(token: string, statusId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `forecast/token/${token}/${statusId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  validateForecastByPeriod<T>(token: string, periodId: number, statusId: number, justification: ICreateCommentModel = null): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `forecast/validateByToken?token=${token}`;

    const formData = new FormData();
    formData.append('periodId', periodId.toString());
    formData.append('statusId', statusId.toString());
    if (justification) {
      formData.append('justification.comment', justification.comment);
      formData.append('justification.periodId', periodId.toString());
      if (justification.files && justification.files.length > 0) {
        justification.files.forEach((file) => { 
          formData.append('justification.files', file); 
        });
      }
    }

    return this.http.post<T>(url, formData).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  validateForecastToken<T>(token: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `forecasts/validateToken/${token}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getForecastSummaryByToken<T>(token: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `forecast/summary?token=${token}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getPrFileBrowser<T>(token: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `sars/fileBrowser/${token}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getPrFileChildAttachments<T>(attachId: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `files/attachments/${attachId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getParsedFile(attachId: string, filename: string): Observable<Blob> {
    const url = this.config.getConfig('apiEndpoint') + `files/parse/${attachId}/${filename}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  approveTrade<T>(sarId: number, token: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/approve/' + sarId + '/' + token;
    return this.http.post<T>(url, {})
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  rejectTrade<T>(sarId: number, token: string,data:any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'trade/reject/' + sarId + '/' + token;
    return this.http.post<T>(url, data)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

}
