import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpUtils} from '../core/utils/http.utils';
import { AppConfig } from 'src/app/app-config.module';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  constructor(
    private http: HttpClient,
    private config: AppConfig
  ) {
  }

  getApp<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'App';
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }
}
