import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterHeaderService } from "../../services/filter-header.service";
import { FilterEventListener } from "../../interfaces/i-filter-event-listener";


/**
 * Component that implements a custom filter for kendo grid filter feature;
 * it only displays filter options based on the distinct values that
 * the column field contains at the moment
 */

@Component({
    selector: 'app-filtering',
    templateUrl: './filtering.component.html',
    styleUrls: [
      './filtering.component.css']
  })

export class FilteringComponent {
  public currentData: any;
  public showFilter = true;
  public gridSearch: string;
  private value: any[] = [];


  constructor(private filterHeaderService:FilterHeaderService) { }
  
  filterGrid(): void {
    this.filterHeaderService.emitEvent(this.gridSearch);
  }

  clearGridFilter(): void {
    this.gridSearch = null
    this.filterHeaderService.emitEvent('');
  }

  hasSearch(){
    return  (this.gridSearch != null && this.gridSearch !== undefined && this.gridSearch.trim().length > 0 );
  }
  
}