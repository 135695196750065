import {Component, OnInit, AfterViewInit, Renderer2} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot, RouterOutlet, NavigationStart, NavigationCancel} from '@angular/router';

import {RoutePartsService} from './shared/services/route-parts.service';
import {ThemeService} from './shared/services/theme.service';

import {select, Store} from '@ngrx/store';
import * as fromCore from './views/core/state';
import * as fromRefdata from './views/refdata/state';

import {filter, takeWhile} from 'rxjs/operators';
import {IFeedbackModel} from './views/core/models/i-feedback.model';
import {Observable} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { slideInAnimation } from './route-animation';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from './shared/services/app.service';
import { AuthService } from './views/user/auth.service';
import { TradingService } from './views/trading/trading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [ slideInAnimation ]
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'PR';
  pageTitle = '';
  feedbacks$: Observable<IFeedbackModel[]>;
  isComponentActive: true;
  
  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private themeService: ThemeService,
    private renderer: Renderer2,
    private store: Store<fromRefdata.State>,
    public snackBar: MatSnackBar,
    private translateService: TranslateService,
    private appService: AppService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.changePageTitle();
    this.feedbacks$ = this.store.pipe(select(fromCore.selectors.getFeedbacks));
    if(this.appService.getAppConfigs().length <= 0 && this.authService.token){
      this.appService.getAppConfigurations().subscribe((res: any[]) => {
        if(res){
          this.appService.setAppConfigs(res);
        }
      });
    }
  }

  ngAfterViewInit() {
    this.themeService.applyMatTheme(this.renderer);
    /*
    this.router.events
    .subscribe((event) => {
    if (event instanceof NavigationStart) {
        console.log("START NAV ::: ", event);    
    }
    else if (event instanceof NavigationEnd) {
      console.log("END NAV ::: ", event);
    }
    else if(event instanceof NavigationCancel){
      console.log("CANCEL NAV ::: ", event);
    }
    });
    */
  }

  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      const routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length) {
        return this.title.setTitle(this.appTitle);
      }

      // Extract title from parts;
      this.pageTitle = routeParts
        .reverse()
        .map((part) => {
          this.translateService.get(part.title).subscribe(titleTranslated => part.title = titleTranslated);
          return part.title;
        })
        .reduce((partA, partI) => {
          let res = '';
          this.translateService.get(partA).subscribe(partAT => {
            this.translateService.get(partI).subscribe(partIT => {
              res = `${partAT} > ${partIT}`
            });
          });
          return res;
        });
      // this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
  
  onActivate(e) {
    return;
    //@dherbe TODO:
    /*
    if (e.constructor.name === "login"){
      return;
    }
    let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20); // how far to scroll on each step
        } else {
            window.clearInterval(scrollToTop);
        }
    }, 16);
    */
  }
}
