import {Injectable, Inject, isDevMode} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, shareReplay} from 'rxjs/operators';
import {HttpUtils} from '../core/utils/http.utils';
import { AppConfig } from './../../app-config.module';
import { IRefdataModel } from './models/i-refdata.model';
import { RefdataDto } from 'src/app/shared/models/refdata-metadata.model';


@Injectable({
  providedIn: 'root'
})
export class RefdataService {

  cache: Object = {};

  constructor(
    private http: HttpClient,
    private config: AppConfig
  ) {}

  getIatfMapping<T>(categoryTypeId: number, categoryId: number, subCategoryId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/categoryMapping?categoryTypeId=' + categoryTypeId + '&categoryId=' + categoryId + '&subCategoryId=' + subCategoryId;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getSuppliers<T>(name:string  = null, code : string = null): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/suppliers';
    let params = {};
    if(name != null) params['name'] = name;
    if(code != null) params['code'] = code;
    return this.http.get<T>(url,{
      params : params
    })
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getRequestors<T>(name:string  = null, cdsid : string = null): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/requestors';
    let params = {};
    if(name != null) params['name'] = name;
    if(cdsid != null) params['cdsid'] = cdsid;
    return this.http.get<T>(url,{
      params : params
    })
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  
  getSupplierCertifications<T>(supplierCode:string  = null): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/supplierCertifications';
    let params = {};
    if(supplierCode != null) params['supplierCode'] = supplierCode;
    return this.http.get<T>(url,{
      params : params
    })
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getRefdataById<T>(id: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/single/' + id;
    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getAllByRefdataTypeId<T>(refdataTypeId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/type/' + refdataTypeId;
    return this.http.get<T>(url)
  }

  getProvisionalMaterial<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'material/provisional';
    return this.http.get<T>(url)
  }

  getCompanyCodes<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'erp/companyCodes';
    return this.http.get<T>(url)
  }
  
  getByRefdataTypeId<T>(refdataTypeId: number): Observable<T> {
    const cacheKey = 'SAR.RefData.' + refdataTypeId;
    if (this.cache[cacheKey]){
      if(isDevMode()){
        console.log(`%cGetting from cache ${cacheKey}`, 'color: green');
      }
      return this.cache[cacheKey];
    }
    
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/' + refdataTypeId;
    this.cache[cacheKey] = this.http.get<T>(url)
      .pipe(
        shareReplay(1),
        catchError(HttpUtils.handleHttpClientError)
      );

    return this.cache[cacheKey];
  }



  getRefdataByFilter<T>(refdataTypeId: number, keywords?: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/' + refdataTypeId + (keywords ? '/' + encodeURI(keywords) : '');
    
    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getCountriesRefdata<T>(): Observable<T> {
    const cacheKey = 'SAR.Countries';
    if (this.cache[cacheKey]){
      if(isDevMode()){
        console.log(`%cGetting from cache ${cacheKey}`, 'color: green');
      }
      return this.cache[cacheKey];
    }

    const url = this.config.getConfig('apiEndpoint') + 'activeCountries';
    this.cache[cacheKey] = this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
      
    );
    return this.cache[cacheKey];
  }

  getExchangeRatesRefdata<T>(): Observable<T> {
    const cacheKey = 'SAR.ExchangeRates';
    if (this.cache[cacheKey]){
      if(isDevMode()){
        console.log(`%cGetting from cache ${cacheKey}`, 'color: green');
      }
      return this.cache[cacheKey];
    }


    const url = this.config.getConfig('apiEndpoint') + 'activeExchangeRates';
    this.cache[cacheKey] = this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );

    return this.cache[cacheKey];
  }

  getMaterials<T>(materialGroupId?: number, keywords?: string, plantId?: number, categoryId?: number, 
    storageLocationId?: number, type?: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/material';

    let params = {};
    if(keywords != null) params['keywords'] = keywords;
    if(materialGroupId != null) params['materialGroupId'] = materialGroupId;
    if(plantId != null) params['plantId'] = plantId;
    if(categoryId != null) params['itemCategory'] = categoryId;
    if(storageLocationId != null) params['storageLocationId'] = storageLocationId;
    if(type != null) params['type'] = type;
    return this.http.get<T>(url, {params: params}).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getMaterialById<T>(id: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/material/single/' + id ;

    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getMaterialsBySarId<T>(sarId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/material/sar/' + sarId ;

    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getMaterialGroups<T>(): Observable<T> {
    const cacheKey = 'SAR.MaterialGroups';
    if (this.cache[cacheKey]){
      if(isDevMode()){
        console.log(`%cGetting from cache ${cacheKey}`, 'color: green');
      }
      return this.cache[cacheKey];
    }

    const url = this.config.getConfig('apiEndpoint') + 'refdatas/materialGroups';
    this.cache[cacheKey] = this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
    return this.cache[cacheKey];
  }

  getChannels<T>(): Observable<T> {
    const cacheKey = 'SAR.Channels';
    if (this.cache[cacheKey]){
      if(isDevMode()){
        console.log(`%cGetting from cache ${cacheKey}`, 'color: green');
      }
      return this.cache[cacheKey];
    }

    const url = this.config.getConfig('apiEndpoint') + 'refdatas/channels';
    this.cache[cacheKey] = this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
    return this.cache[cacheKey];
  }

  getAssetNumbers<T>(keywords?: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/assets' + (keywords ? `?keywords=${encodeURI(keywords)}` : '');

    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getAssetNumberById<T>(id: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/asset/' + id;

    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getDepartments<T>(parentId?: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/departments' + (parentId ? `?parentId=${parentId}` : '');

    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getDepartment<T>(id: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/department/' + id;
    
    return this.http.get<T>(url).pipe(
      shareReplay(1),
      catchError(HttpUtils.handleHttpClientError)
    );
  }
    
  getCostCenterByOrderNumberId<T>(id: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/costCenterByOrder/' + id;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }
  getCostCenterDetail<T>(id: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'workflows/costCenter?costCenterId=' + id;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  addCostCenterResponsible<T>(body:any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `workflows/costCenter`;
    return this.http.post<T>(url, body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  patchCostCenter<T>(body:any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `workflows/costCenter`;
    return this.http.patch<T>(url, body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }


    /**
   * Allows retrieval of Metadata about a RefdataType, how many usages it has and/or other metrics.
   * @returns T, which should always be an Array of the metadata return.
   */
  getRefdataMetadata<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/metadata'; 

    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  update<T>(refdata: IRefdataModel): Observable<T>{
    const cacheKey = 'SAR.RefData.' + refdata.refdataTypeId;
    delete this.cache[cacheKey];
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/update';
    return this.http.post<T>(url, refdata).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  create<T>(refdata: IRefdataModel): Observable<T>{
    const cacheKey = 'SAR.RefData.' + refdata.refdataTypeId;
    delete this.cache[cacheKey];
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/create';
    return this.http.post<T>(url, refdata).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getMaterialDescription<T>(materialId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `refdatas/material/registerInfoDescription/${materialId}`;
    //const url = this.config.getConfig('apiEndpoint') + 'refdatas/material/registerInfoDescription/' + materialId;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  enableSelectedMaterial<T>(materialId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `refdatas/material/${materialId}/enable`;
    //const url = this.config.getConfig('apiEndpoint') + 'refdatas/material/registerInfoDescription/' + materialId;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  disableSelectedMaterial<T>(materialId: number): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `refdatas/material/${materialId}/enable`;
    //const url = this.config.getConfig('apiEndpoint') + 'refdatas/material/registerInfoDescription/' + materialId;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getDocumentTypes<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/documentTypes'
    return this.http.get<T>(url)
    .pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  postDocumentTypes<T>(body: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/documentTypes'
    return this.http.post<T>(url, body)
    .pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  updateDocumentTypes<T>(body: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/documentTypes'
    return this.http.patch<T>(url, body)
    .pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getRefdataParent<T>(refDataTypeId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/' + refDataTypeId + '/parents';
    return this.http.get<T>(url)
    .pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getCostCenters<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/costcenters';
    return this.http.get<T>(url)
    .pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }
  
  updateCostCenters<T>(body: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/costcenter';
    return this.http.patch<T>(url, body)
    .pipe(
      catchError(HttpUtils.handleHttpClientError)
      );
    }

  getAssets<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/assets';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
    }
  patchAssets<T>(body: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'refdatas/asset ';
    return this.http.patch<T>(url, body)
    .pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }
  getMasterDataMaterials<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'material/';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
    }
  patchMaterials<T>(body: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'material/';
    return this.http.patch<T>(url, body)
    .pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }
  postMaterials<T>(body: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'material/';
    return this.http.patch<T>(url, body)
    .pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getActiveExchangeRates<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'ActiveExchangeRates';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getServiceTypes<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'serviceType';
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getCountries<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'country';
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getSalesOrgs<T>(): Observable<T>{   
    const url = this.config.getConfig('apiEndpoint') + `trade/salesOrg`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getPlantBySales<T>(salesOrgCode: string): Observable<T>{   
    const url = this.config.getConfig('apiEndpoint') + `trade/plantsBySalesOrg/${salesOrgCode}`;
    return this.http.get<T>(url)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  addRefdata<T>(refdataDto: RefdataDto): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `refdatas/create`;
    return this.http.post<T>(url, refdataDto)
    .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }


}
