import { Component, HostListener, Inject, OnInit, Renderer2, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

/**
 * Component that deals with the confirmation of an action by the user
 */
@Component({
    selector: 'app-confirm-cancel',
    templateUrl: './confirm-cancel.component.html',
    styleUrls: ['./confirm-cancel.component.css',],
    
  })

export class ConfirmCancelComponent implements OnInit{
  
  public roleFormGroup: FormGroup;    //formGroup that contains the new role
  public table;
  public delta: any;
  constructor(private dialogRef: MatDialogRef<ConfirmCancelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){
  }

  ngOnInit(): void {
    this.delta = (this.data?.additionalCreditData?.creditLimitAmount - this.data?.additionalCreditData?.staticCreditExposureAmount) - this.data?.totalCost;
  }

  /**
   * closes the dialog
   */
  public closeDialog(close: boolean){
    this.dialogRef.close({close: close});
  }

  public getCreditLimitValue(): string{
    if(this.delta < 0){
      return "Credit limit exceeded!";
    }else {
      return "Credit limit not exceeded! ";
    }
  }

}
