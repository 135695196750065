import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { catchError, filter } from 'rxjs/operators';
import { AppConfig } from 'src/app/app-config.module';
import { HttpUtils } from '../../views/core/utils/http.utils';
import { ICreateCommentModel } from '../../views/forecast/models/create-comment.model';
import { IHeaderTemplateModel } from 'src/app/i-header-template';
import { NavigationEnd, Router } from '@angular/router';
import { AppService } from './app.service';
import { IAppSettingsModel } from 'src/app/views/refdata/models/i-app-settings.model';

@Injectable({
  providedIn: 'root',
})
export class TopbarService {

  private currentContext: any = {};
  appSettings: IAppSettingsModel = {};

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    private router: Router,
    private appService: AppService
  ) {
    // Subscribe to router events
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        var context = this.getContext();
        this.clearContext();
        
      });
      this.appSettings = this.appService.getAppSettings();
  }

  getApp<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'App';
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }
  
  getAppName<T>(): string {
    return this.appSettings?.name;
  }

  getAppTitle(): string{
    return this.appSettings?.title;
  }

  getTitle<T>(): string {
    const opt = this.loadConfig();
    return opt.title || 'My Trades';
  }

  private loadConfig(): IHeaderTemplateModel {
    //load local options for all modules in storage
    let options = JSON.parse(localStorage.getItem('plab-context'));
    if (!options) options = {};
    return options;
  }
  //safe config to datastorage
  private saveConfig(options) {
    this.currentContext = options;
    localStorage.setItem('plab-context', JSON.stringify(options));
  }

  setTitle<T>(title: string) {
    let opt = this.loadConfig();
    opt.title = title;
    this.saveConfig(opt);
  }

  getContext<T>(): any[] {
    const opt = this.loadConfig();
    return opt.context || [];
  }

  addContext<T>(context: string) {
    let opt = this.loadConfig();
    opt.context = [...(opt.context || []), context];
    this.saveConfig(opt);
  }

  removeContext<T>(index) {
    let opt = this.loadConfig();
    opt.context = opt.context.slice(index, 1);
    this.saveConfig(opt);
  }

  clearContext<T>() {
    let opt = this.loadConfig();
    opt.context = [];
    this.saveConfig(opt);
  }

  getCurrentContext(){
    return this.currentContext;
  }

  updateContextElementByIndex(index: number, newContext: string){
    if(index >= 0){
      //this.currentContext[index] = newContext;
      let opt = this.loadConfig();
      if(opt.context){
        opt.context[index] = newContext;
        this.saveConfig(opt);
      }
    }
  }

  setContext(newContext: any[]){
    this.currentContext
  }
}
