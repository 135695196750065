<div class="sidenav-hold">
  <div class="icon-menu" *ngIf="hasIconTypeMenuItem">
    <!-- Icon menu separator -->
    <div class="mt-1 mb-1 nav-item-sep">
      <mat-divider [ngStyle]="{margin: '0 -24px'}"></mat-divider>
      <span class="text-muted icon-menu-title">{{iconTypeMenuTitle}}</span>
    </div>
    <!-- Icon menu items -->
    <div class="icon-menu-item" *ngFor="let item of menuItems">
      <button *ngIf="!item.disabled && item.type === 'icon'" mat-raised-button [matTooltip]="item.tooltip" routerLink="/{{item.state}}"
        routerLinkActive="mat-bg-primary">
        <mat-icon>{{item.icon}}</mat-icon>
      </button>
    </div>
  </div>

  <ul appDropdown class="sidenav">
    <!--<li (click)="setExpandedByDefault()" *ngFor="let item of menuItems" appDropdownLink [class.open]="expandParentByDefault(item)">-->
    <li *ngFor="let item of menuItems" appDropdownLink>
      <div class="nav-item-sep" *ngIf="item.type === 'separator'">
        <mat-divider></mat-divider>
        <span class="text-muted">{{item.name | translate}}</span>
      </div>
      <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
        <a class="nav-item" [routerLink]="buildParentLink(item)" appDropdownToggle matRipple *ngIf="item.type === 'link' || item.sub?.length == 1">
          <mat-icon>{{buildParentIcon(item)}}</mat-icon>
          <span class="item-name lvl1">{{buildParentName(item) | translate}}</span>
          <span fxFlex></span>
          <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}" *ngFor="let badge of item.badges">{{ badge.value }}</span>
        </a>
        <a [href]="item.state" appDropdownToggle matRipple *ngIf="item.type === 'extLink'" target="_blank">
          <mat-icon>{{item.icon}}</mat-icon>
          <span class="item-name lvl1">{{item.name | translate}}</span>
          <span fxFlex></span>
          <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}" *ngFor="let badge of item.badges">{{ badge.value }}</span>
        </a>

        <!-- DropDown -->
        <a *ngIf="item.type === 'dropDown' && item.sub?.length > 1" appDropdownToggle matRipple>
          <mat-icon>{{item.icon}}</mat-icon>
          <span class="item-name lvl1">{{item.name | translate}}</span>
          <span fxFlex></span>
          <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}" *ngFor="let badge of item.badges">{{ badge.value }}</span>
          <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
        </a>
        <!-- LEVEL 2 -->
        <ul class="submenu lvl2" (click)="onClickLvl2WhenLvl3Exists(item)" appDropdown *ngIf="item.type === 'dropDown' && item.sub?.length > 1">

          <li *ngFor="let itemLvL2 of item.sub"  appDropdownLink routerLinkActive="open" [class.selected-menu]="currentRoute == buildRouterLink(item, itemLvL2)" [class.open]="(currentRoute == buildRouterLink(item, itemLvL2)) || alternativeExpandEventCondition(itemLvL2)">

            <a [routerLink]="buildRouterLink(item, itemLvL2)" appDropdownToggle *ngIf="itemLvL2.type !== 'dropDown'"
              matRipple>
              <mat-icon>{{itemLvL2.icon}}</mat-icon>
              <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
              <span fxFlex></span>
            </a>

            
            <a *ngIf="itemLvL2.type === 'dropDown' && itemLvL2.sub?.length > 1" appDropdownToggle matRipple>
              <mat-icon>{{itemLvL2.icon}}</mat-icon>
              <span class="item-name lvl2">{{itemLvL2.name | translate}}</span>
              <span fxFlex></span>
              <span class="menuitem-badge mat-bg-{{ badge.color }}" [ngStyle]="{background: badge.color}" *ngFor="let badge of item.badges">{{ badge.value }}</span>
              <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
            </a>
            <ul class="submenu lvl2" appDropdown *ngIf="itemLvL2?.type === 'dropDown' && itemLvL2?.sub?.length > 1">
              <li *ngFor="let itemLvL3 of itemLvL2?.sub" appDropdownLink routerLinkActive="open" [class.selected-menu]="currentRoute == buildRouterLink(item, itemLvL2, itemLvL3)" [class.open]="currentRoute == buildRouterLink(item, itemLvL2, itemLvL3)">
                <a style="padding-left: 50px;"  [routerLink]="buildRouterLink(item, itemLvL2, itemLvL3)" appDropdownToggle *ngIf="itemLvL3.type !== 'dropDown'"
                  matRipple>
                  <mat-icon>{{itemLvL3.icon}}</mat-icon>
                  <span class="item-name lvl2">{{itemLvL3.name | translate}}</span>
                  <span fxFlex></span>
                </a>
              </li>

            </ul>
  

            
            <!-- LEVEL 3 -->
            <!--
            <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
              <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                <a [routerLink]="buildRouterLink(item, itemLvL2, itemLvL3)" appDropdownToggle matRipple>
                  <span class="item-name lvl3">{{itemLvL3.name | translate}}</span>
                </a>
              </li>
            </ul>
            -->
          </li>
        </ul>
        
      </div>
    </li>
    
  </ul>
  <!-- <ul appDropdown class="sidenav" style="position: absolute;bottom: 0px;width: 100%;">
      <li>
        <div class="lvl1">
          <a matRipple (click)="openHelp()" style="margin:0;padding:0;border-radius: 0px !important;">
            <mat-icon>help</mat-icon>
            <span class="item-name lvl1">{{'Help' | translate}}</span>
            <span fxFlex></span>
          </a>
          </div>
      </li>
  </ul> -->
</div>
