import {Injectable, Inject} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {isDevMode} from '@angular/core';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {SESSION_STORAGE, WebStorageService} from 'ngx-webstorage-service';
import { NavigationService } from '../navigation.service';
import { SarService } from 'src/app/views/sar/sar.service';
import { Store } from '@ngrx/store';
import * as fromSar from '../../../views/sar/state';
import * as userActions from '../../../views/user/state/user.actions';
import { AuthService } from 'src/app/views/user/auth.service';
import { TradingService } from 'src/app/views/trading/trading.service';

@Injectable()
export class AuthGuard implements CanActivate {
  private isAuthenticated = false; // Set this value dynamically
  private userData = null;
  public returnUrl : string = null;
  
  public BuyerRoleName : string = 'Buyer';
  public ManagerRoleName : string = 'Manager';
  public DirectorRoleName : string = 'Director';
  public ObserverRoleName : string = 'Observer';
  public ChairmanRoleName : string = 'Chairman';
  public AdminRoleName : string = 'Admin';
  public VPRoleName : string = 'VicePresident';
  public SuperUserRoleName: string = "SuperUser";

  public ForecastPlanner: string = "Planner";
  public ForecastEvaluator: string = "Evaluator";
  public ForecastApprover: string = "Approver";
  
  public acessUser : any; 

  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private router: Router,
    @Inject(SESSION_STORAGE) private storage: WebStorageService,
    private navigation: NavigationService,
    private sarService:SarService,
    private store: Store<fromSar.State>,
    private authService : AuthService,
    private tradingService: TradingService
  ) {
      this.userData = storage.get("SARUserData");
  }

  getUserAcesses(){
    this.sarService.getUserAcess().subscribe(res =>{
      if(res){
        this.acessUser = res;
      }
    })
  }

  getUserAcess(): any[]{
    
    return this.acessUser;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if(this.redirectIfNeed(state)){
      return false;
    }

    if (this.isAuthenticated /*|| state.url.includes('/trade/approve/') || state.url.includes('/trade/reject/')*/) {
      return true;
    }
    if(isDevMode() && this.userData != null){
      console.log(this.userData);
    }
    if(typeof(this.userData) != 'undefined' && this.userData != null){
      var d = new Date(this.userData.expiration);
      if(d.getTime() > new Date().getTime()){
        this.isAuthenticated = true; 
        this.filterMenuByUserRoles();
        return true;
      }
    }
    this.returnUrl = state.url;
    this.logout();
    return false;
  }
  

  hasRedirected = false;
  private redirectIfNeed(state: RouterStateSnapshot){
    if(this.hasRedirected) return false;
    if(!this.hasParsedMenu) return false;

    this.hasRedirected = true;

    if(this.navigation.userMenu.length == 0){
      let userId = this.getProperty('id');
      this.store.dispatch(new userActions.SelectedUserId(userId));
      this.store.dispatch(new userActions.GetUser(userId));
      this.router.navigate([`/user/user-details/${this.getProperty('id')}`]);
      return true;
    }

  
    var firstMenuItem = this.navigation.userMenu[0];
    if (!state.url.includes("sar/validate-sar/") && !state.url.includes("forecast")  && state.url != `/${firstMenuItem.state}`){
      //TODO: ??
      this.router.navigate([`/${firstMenuItem.state}`]);
      return true;
    }
    // if (state.url != "/sar/sar-dashboard" && !state.url.includes("sar/validate-sar/")){
    //   this.router.navigate(["/sar/sar-dashboard"]);
    // }
    return false;
  }
  
  hasParsedMenu : boolean = false;

  private filterMenuByUserRoles(){
    var menu = [];
    this.navigation.defaultMenu.forEach(parentMenuItem => {
      let canAdd = false;
      if(parentMenuItem.roles != null){
        for(let r = 0; r < parentMenuItem.roles.length; r++){
          if(this.isInRole(parentMenuItem.roles[r])){
            canAdd = true;
            if (parentMenuItem.sub && parentMenuItem.sub.length > 0){
              parentMenuItem.sub = parentMenuItem.sub.filter(item => item.roles.some(r => this.isInRole(r)));
            }
            break;
          }
        }
      } else {
        canAdd = true;
      }
      if(canAdd) menu.push(parentMenuItem);
    });

    if (menu.length == 1 && menu[0].sub && menu[0].sub.length > 0){
      menu = menu[0].sub;
    }

    this.navigation.updateMenu(menu);
    this.hasParsedMenu = true;
  }

  logout(){
    this.userData = null;
    this.isAuthenticated = false;

    //this.navigation.resetMenu();
    this.authService.clearToken();
    let args = {};
    if(this.returnUrl != null && this.returnUrl != '' && this.returnUrl != '/dashboard'){
      args = { queryParams: { returnUrl: this.returnUrl } };
    }
    this.storage.remove("SARUserData");
    localStorage.removeItem("SARUserData");
    this.router.navigate(['/sessions/signin'], args);
  }

  doLogin(data : any){
    this.userData = data;
    this.storage.set("SARUserData", data);
    if(isDevMode()){
      console.log(this.userData);
    }
    this.isAuthenticated = true;

    this.filterMenuByUserRoles();
    this.tradingService.fetchComponents();
    this.blockUI.stop();
  }

  getToken(){
    return this.getProperty('token')
  }

  isManager(){ return this.isInRole(this.ManagerRoleName); }
  isBuyer(){ return this.isInRole(this.BuyerRoleName); }
  isDirector(){ return this.isInRole(this.DirectorRoleName); }
  isChairman(){ return this.isInRole(this.ChairmanRoleName); }
  isObserver(){ return this.isInRole(this.ObserverRoleName); }
  
  isAdmin(){ return this.isInRole(this.AdminRoleName)}
  isSuperUser() { return this.isInRole(this.SuperUserRoleName); }

  isForecastPlanner(){ return this.isInRole(this.ForecastPlanner); }
  isForecastEvaluator() { return this.isInRole(this.ForecastEvaluator); }
  isForecastApprover() { return this.isInRole(this.ForecastApprover); }

  isInRole(role : string){
    if(this.userData == null) return false;
    if(this.userData.roles.length == 0) return false;
    for(var i = 0; i < this.userData.roles.length; i++){
      if(this.userData.roles[i].toLowerCase() == role.toLowerCase() || this.userData.roles[i].toLowerCase() == this.SuperUserRoleName.toLowerCase()){
        return true;
      }
    }
    return false;
  }

  getProperty(key : string){
    if(this.userData == null) return '';
    return this.userData[key];
  }
  
  setIsAuthenticated(isAuthenticaded: boolean) {
    this.isAuthenticated = isAuthenticaded;
  }
  getColor(userId : string){
    return "#eee";
  }

  isCurrentUser(userId: string): boolean{
    return this.userData.id == userId;
  }

  hasBulkUploadEnabled(){
    return this.userData.company?.departments?.some(department => department.enableBulkUpload);
  }

}
