import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterService } from "@progress/kendo-angular-grid";
import { distinct, filterBy, FilterDescriptor } from "@progress/kendo-data-query";

/**
 * Component that implements a custom filter for kendo grid filter feature;
 * it only displays filter options based on the distinct values that
 * the column field contains at the moment
 */
@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.css']
  })

export class SearchInputComponent implements OnInit {

  @Input() public placeholder: string;
  @Input() public style: string = "width: 300px;";
  @Input() public class: string;
  @Output() public filter = new EventEmitter<string>();
  @Output() public clear = new EventEmitter<boolean>();

  public gridSearch: string;

  constructor(){}

  ngOnInit(): void {
  }

  public clearGridFilter(): void{
    this.gridSearch = "";
    this.clear.emit(true);
  }

  public filterGrid(): void{
    if(this.gridSearch == ''){
      this.clear.emit(true)
    }
    else{
    this.filter.emit(this.gridSearch);
    }
  }



}