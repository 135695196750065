import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterEventListener } from '../interfaces/i-filter-event-listener';

@Injectable({
  providedIn: 'root'
})
export class FilterHeaderService {

  constructor(){
  }
  public listeners: FilterEventListener[] = [];

  // Método para adicionar ouvintes
  addListener(listener: FilterEventListener) {
    this.listeners.push(listener);

  }

  // Método para emitir o evento
  emitEvent(keyword: string) {
    this.listeners.forEach(listener => {
        listener.onEvent(keyword);
    });
  }

}