import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent implements OnInit {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  @Output() helpClicked = new EventEmitter<void>();

  constructor(private router: Router) {}
  ngOnInit() {}

  openHelp(){
    this.helpClicked.emit();
  }

  get currentRoute(): string{
    return this.router.url;
  }

  buildRouterLink(item, item2 = null, item3 = null): string{
    let link: string = "/";

    if (item && item.state){
      link += item.state;
    }

    if (item2){
      if (item2.fullState){
        link = "/" + item2.fullState;
      }else if (item2.state){
        link += "/" + item2.state;
      }
    }

    if (item3){
      if (item3.fullState){
        link = "/" + item3.fullState;
      }else if (item2.state){
        link += "/" + item3.state;
      }
    }
    return link;
  }

  buildParentLink(item: any){
    if(item.sub && item.sub.length == 1){
      return this.buildRouterLink(item, item.sub[0]);
    }

    return "/" + item.state;
  }

  buildParentName(item: any){
    if(item.sub && item.sub.length == 1){
      return item.sub[0].name;
    }

    return item.name;
  }

  buildParentIcon(item: any){
    if(item.sub && item.sub.length == 1){
      return item.sub[0].icon;
    }

    return item.icon;
  }

  onClickItem(itemLvL2){
    console.log("ITEM LVL 2 ::: ", itemLvL2);
  }

  expandedLvl2: any = null;
  onClickLvl2WhenLvl3Exists(item){
    console.log("LVL 2 CLICKED ::: ");
    console.log("ITEM ::: ", item);
  }

  alternativeExpandEventCondition(itemLvl2: any){
    return;
  }

  expandedByDefault = true;
  expandParentByDefault(item): boolean{
    console.log("MENU ITEMS ::: ", this.menuItems);
    console.log("item ::: ", item);
    console.log("DEFAULT VALUE ::: ", this.expandedByDefault);
    return (this.currentRoute.includes("buy") && item.name == "Buy") || (this.currentRoute.includes("sell") && item.name == "Sell") && this.expandedByDefault;
  }

  setExpandedByDefault(){
    this.expandedByDefault = !this.expandedByDefault;
  }
}