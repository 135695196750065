import {Injectable, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpUtils} from '../core/utils/http.utils';
import {environment} from '../../../environments/environment';
import {ILoginModel} from './models/i-login.model';
import {ICreateUserModel} from './models/create-user/i-create-user.model';
import {IUpdateUserModel} from './models/create-user/i-update-user.model';
import { AppConfig } from './../../app-config.module';
import {SESSION_STORAGE, WebStorageService} from 'ngx-webstorage-service';
import { AuthGuard } from 'src/app/shared/services/auth/auth.guard';

@Injectable({
  providedIn: 'root'

})

export class AuthService {
  token: string;

  constructor(
    private http: HttpClient,
    private config: AppConfig,
    @Inject(SESSION_STORAGE) private storage: WebStorageService
  ) {
    this.token = storage.get("SARAuthKey");
  }
  /**
  * @ngdoc property set
  * @name handleHttpClientError
  * @methodOf AuthService
  * @location /app/views/user/auth.service.ts
  * @description sets the auth token obtained in UserEffects login$
  * method just to be used during this session
  */
 setToken(token: string): void {
    this.token = token;
    this.storage.set("SARAuthKey", token);
  }
  /**
  * @ngdoc property get
  * @name handleHttpClientError
  * @methodOf AuthService
  * @location /app/views/user/auth.service.ts
  * @description gets the auth token for the current user during this session
  */
 getToken(): string {
    return this.token;
  }

  clearToken():void{
    this.token = null; 
    this.storage.remove("SARUserData");
    this.storage.remove("SARAuthKey");
  }

  parseToken(token): any {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  create<T>(req: ICreateUserModel): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users';
    return this.http.post<T>(url, req)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  update<T>(req: IUpdateUserModel): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users';
    return this.http.patch<T>(url, req)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  login<T>(req: ILoginModel): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'auth';
    return this.http.post<T>(url, req)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  logout<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'auth' + "/signout";
    return this.http.post<T>(url, "")
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  resetPassword<T>(req: IUpdateUserModel): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users/resetPassword';
    return this.http.post<T>(url, req)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getProviders<T>(): Observable<T>{
    return this.http.get<T>(this.config.getConfig('apiEndpoint') + 'account/providers').pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  loginWithToken<T>(token: string): Observable<T>{
    this.setToken(token);
    return this.http.get<T>(this.config.getConfig('apiEndpoint') + `auth/user`).pipe(
      // map(userData => {
      //   if (userData) {
      //     this.authGuard.doLogin(userData);
      //   }
      //   return {} as T;
      // }),
      catchError(HttpUtils.handleHttpClientError)
    );
  }
}
