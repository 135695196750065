<div class="trading" style="flex-direction: row-reverse !important; width: 100%;">
    <!--  -->
    <mat-form-field class="custom-placeholder">
        <input matInput placeholder="{{'Search...' | translate}}"  [(ngModel)]="gridSearch" (keyup.enter)="filterGrid()">
        <button mat-icon-button matSuffix (click)="filterGrid()">
            <mat-icon>search</mat-icon>
        </button>
    </mat-form-field>
    <button mat-icon-button class= "clear-filters-button" (click)="clearGridFilter()">
      <!--<span>{{'Clear filters' | translate}} </span>-->  
      <span style="font-size: 23px; color: #ccc;" class="k-icon clear-filter-icon k-font-icon k-i-filter-clear "></span>
    </button>
    <!-- <div class="total-div-class"><span class="total-span-class">Total: {{getTotalTrades()}}</span></div> -->
</div>