<div style="text-align: center;">
  <img style="width: 20%; height: 30%;" src="assets/images/Arpadis.jpg">
  <div *ngIf="!displayMessage && !error" class="result-message">
    <div *ngIf="isApproval">
      <span *ngIf="isApproval">
        Approving, please wait ...
      </span>
      <div style="text-align: center; margin-top: 20px;">
        <kendo-loader size="large"> </kendo-loader>
      </div>
    </div>
    <div *ngIf="!isApproval && !error" style="text-align: -webkit-center;">
      <mat-card *ngIf="!isApproval && !status && !error">
        <form [formGroup]="formGroup" >
          <mat-card-title>Are you sure you want to reject this trade? If so, please insert the rejection notes below and press 'Reject' to confirm your decision.</mat-card-title>
          <mat-dialog-content style="height:auto !important">
            <mat-form-field  class="FormFieldNotes" style="width:100%;">
              <textarea matInput  class="remarksTextarea" placeholder="{{'Reasons for rejection' | translate}}" formControlName="reasonsForRejection" rows="5" col="5" required></textarea>
            </mat-form-field>
          </mat-dialog-content>
          <mat-dialog-actions>
            <button  mat-raised-button color="primary" [disabled]="!formGroup.valid"  (click)="send()">{{'Reject' | translate}}</button>
            <button  mat-raised-button color="warn"  (click)="send()">{{'Cancel' | translate}}</button>
          </mat-dialog-actions>
        </form>
      </mat-card>
    </div>
  </div>
  <div *ngIf="displayMessage">
    <div *ngIf="isApproval && !error" class="result-message">
      The trade has been approved! You can close this window.
    </div>
    <div *ngIf="status && !error" class="result-message">
      The trade has been rejected! You can close this window.
    </div>
    <div>
      <span *ngIf="isApproval && !error" class="fas fa-check-circle approve-icon"></span>
      <span *ngIf="!isApproval && !error" class="fas fa-times-circle reject-icon"></span>
    </div>
  </div>
  <div *ngIf="error" class="result-message">
    It was not possible to complete your action! Please, try to refresh this page or generate a new email.
    <div>
      <span class="fas fa-exclamation-triangle warning-icon"></span>
    </div>
  </div>
</div>

<!--
<div class="container">
  <div class="child-container">
    <mat-card *ngIf="!displayMessage">
      <div *ngIf="isApproval" class="result-message">
        Interacting with SAP... Please wait ...
      </div>
      <div>
        <kendo-loader size="large"> </kendo-loader>
      </div>
    </mat-card>
    <mat-card *ngIf="displayMessage">
      <div *ngIf="isApproval" class="result-message">
        The trade has been approved! You can close this window.
      </div>
      <div *ngIf="status" class="result-message">
        The trade has been rejected! You can close this window.
      </div>
    </mat-card>

    <mat-card *ngIf="!isApproval && !status">
      <form [formGroup]="formGroup" >
        <mat-card-title>Are You Sure ?</mat-card-title>
        <mat-dialog-content style="height:auto !important">
          <mat-form-field  class="FormFieldNotes" style="width:100%;">
            <textarea matInput  class="remarksTextarea" placeholder="{{'Reasons for rejection' | translate}}" formControlName="reasonsForRejection" rows="10" col="5" required></textarea>
          </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions>
          <button  mat-raised-button color="primary" [disabled]="!formGroup.valid"  (click)="send()">{{'Reject' | translate}}</button>
          <button  mat-raised-button color="warn"  (click)="send()">{{'Cancel' | translate}}</button>
        </mat-dialog-actions>
      </form>
    </mat-card>
  </div>
</div>
-->