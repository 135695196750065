<div class="sidebar-panel" style="position:absolute;">
  <div style="position: absolute;left: 0;bottom: 0;right: 0;color: #eee;z-index: 1;padding: 20px;text-align: center;background: #7792b4;text-transform: uppercase;">
    <a *ngIf="getAppName() != 'Arpadis Trading Portal'" href="https://plab.brightenconsulting.com/Files/Docs/Brighten_AppMyProcurement_ManualUtilizadorv1.4_3105202311.pdf" target="_blank" rel="noopener noreferrer">{{ 'User manual' | translate }}</a>
    <a *ngIf="getAppName() == 'Arpadis Trading Portal'" href="https://repo.brightenconsulting.com/trading/AppMyTrading_Trading_Quick_Guide_v1.pdf" target="_blank" rel="noopener noreferrer">{{ 'User manual' | translate }}</a>

  </div>
  <mat-toolbar class="topbar topbar-white">
    <span style="width: 100%; text-align: center; font-size: 19px;">{{getAppTitle() | translate}}</span>
  </mat-toolbar>
  <div id="scroll-area" [perfectScrollbar] class="navigation-hold" fxLayout="column">
    <!-- Sidebar user -->
    <div class="app-user" style="position: absolute;left:0; right:0; bottom:130px;">
      <!-- Small buttons -->
      <div class="app-user-controls" style="margin-right: 15px;">
        <!--
        <button style="color: #434341;"
                mat-icon-button mat-xs-button matTooltip="{{'Settings' | translate}}"
                [matMenuTriggerFor]="appUserMenu" >
          <mat-icon>manage_accounts</mat-icon>
        </button>
        -->
        <button style="color: #434341;"
                mat-icon-button mat-xs-button matTooltip="{{'Profile' | translate}}"
                (click)="navigateToProfile()">
          <mat-icon>manage_accounts</mat-icon>
        </button>
        <button style="display:none; color: white;"
                routerLink="/inbox">
          <mat-icon>email</mat-icon>
        </button>
        <button style="color: #434341;"
                mat-icon-button mat-xs-button matTooltip="{{'Sign out' | translate}}"
                routerLink="/sessions/signin">
          <mat-icon>power_settings_new</mat-icon>
        </button>

        <mat-menu #appUserMenu="matMenu">
          <button mat-menu-item (click)="navigateToProfile()">
            <mat-icon>account_box</mat-icon>
            <span>{{'Profile' | translate}}</span>
          </button>
          <button mat-menu-item (click)="signout()">
            <mat-icon>exit_to_app</mat-icon>
            <span>{{'Sign out' | translate}}</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <!-- App Logo -->
    <div class="branding default-bg no-bg" style="background: transparent !important; top: 40px!important; display: none;">
      <!-- Two different logos for dark and light themes -->
      <img src="assets/images/logo-text-white.png" alt="" class="app-logo-text"
          *ngIf="themeService.activatedTheme?.name?.indexOf('dark') !== -1" style="display:none">
      <img src="{{appSettings.logoUrl}}" alt="" class="app-logo-text"
           *ngIf="themeService.activatedTheme?.name?.indexOf('dark') === -1 && !appSettings.logoWhiteUrl">
      <img src="{{appSettings.logoWhiteUrl}}" alt="" class="app-logo-text"
            *ngIf="themeService.activatedTheme?.name?.indexOf('dark') === -1 && appSettings.logoWhiteUrl">
    </div>


    <!-- Navigation -->
    <app-sidenav (helpClicked)="openHelp($event)" [items]="menuItems" [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"></app-sidenav>
  </div>
</div>


<app-help #sideHelp></app-help>