import { Injectable, Inject } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ICreateUserModel} from './models/create-user/i-create-user.model';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpUtils} from '../core/utils/http.utils';
import {ICoreSearchModel} from '../core/models/i-core-search.model';
import { AppConfig } from './../../app-config.module';
import { IUserDelegationModel } from './models/i-user-delegation.model';
import { IWorkflowModel } from '../workflows/models/i-workflow.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private usersList: any[] = [];
  constructor(
    private http: HttpClient,
    private config: AppConfig
    ) {
  }

  getById<T>(req: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users' + `/${req}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  search<T>(req: string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users' + `/search/${req}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getUsers<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users';
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getDashboard<T>(req: ICoreSearchModel = null): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users' + `/dashboard`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getDashboardUsers<T>(req: ICoreSearchModel = null): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + `dashboard/` + 'users' ;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getUsersByModule<T>(moduleName : string): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users' + `/moduleUsers/${moduleName}`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getRoles<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users' + `/roles`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getRoleAttributes<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'users' + `/roleAttributes`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }
  
  getDepartments<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/departments';
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getWorkflowsDep<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'workflows/approvalFlow';
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }

  getDepUsers<T>(departmentId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `workflows/department/${departmentId}/approvers`;
    return this.http.get<T>(url)
      .pipe(
        catchError(HttpUtils.handleHttpClientError)
      );
  }
  

  getDelegations<T>(userId: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `users/delegations?userId=${userId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  addDelegation<T>(delegation: IUserDelegationModel): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `users/addDelegation`;
    return this.http.post<T>(url, delegation).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  patchDelegation<T>(delegation: IUserDelegationModel): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `users/patchDelegation`;
    return this.http.patch<T>(url, delegation).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  stopDelegation<T>(delegationId: number, userId: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/stopDelegation';

    return this.http.post<T>(url, {id: delegationId, userId: userId}).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  deleteDelegation<T>(delegationId: number, userId: string, delegatedToUserId: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/deleteDelegation';

    return this.http.post<T>(url, {id: delegationId, userId: userId,delegatedToUserId:delegatedToUserId}).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getWorkFlows<T>(departmentId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/workflows/' + departmentId;

    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }
  getWorkFlowsHistory<T>(departmentId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') +`workflows/department/${departmentId}/history`;

    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  addEditWorkFlow<T>(workFlow: IWorkflowModel): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/workflowstage';

    return this.http.post<T>(url, workFlow).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  deleteWorkFlow<T>(workflowId: number): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/deleteWorkflowStage';

    return this.http.post<T>(url, {id: workflowId}).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  uploadAvatar<T>(userId: string, file: File): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/uploadAvatar?userId=' + userId;

    const formData: FormData = new FormData();
    formData.append('files', file, file.name);
    
    return this.http.post<T>(url, formData).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getUsersByRole<T>(roleId: string): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + `users/byRole/${roleId}`;
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getNotification<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/notifications';

    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  addNotification<T>(body:any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/notifications';

    return this.http.post<T>(url, body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  patchNotification<T>(body: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'users/notifications';

    return this.http.post<T>(url, body).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  public getUsersList(): any[]{
    return this.usersList;
  }

  public setUsersList(usersList: any[]){
    this.usersList = usersList;
  }
}
