import {Routes} from '@angular/router';
import {AdminLayoutComponent} from './shared/components/layouts/admin-layout/admin-layout.component';
import {AuthLayoutComponent} from './shared/components/layouts/auth-layout/auth-layout.component';
import {AuthGuard} from './shared/services/auth/auth.guard';
import { ApprovalDecisionComponent } from './views/public/approval-decision/approval-decision.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'sessions/signin',
    pathMatch: 'full',
    data: {animation: 'Home'}
  }, {
    path: '',
    component: AuthLayoutComponent,
    data: {animation: 'Home'},
    children: [
      {
        path: 'sessions',
        // loadChildren: './views/sessions/sessions.module#SessionsModule',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: {title: 'Session',animation: 'Home'}
      },
      {
        path: 'public',
        loadChildren: () => import('./views/public/public.module').then(m => m.PublicModule),
        data: {title: 'Public',animation: 'Home'}
      }
    ]
  },{
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    data: {animation: 'Home'},
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: {title: 'My Activity', breadcrumb: 'PRs', animation: 'Home'},
      },
      {
        path: 'admin',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: {title: 'Event viewer', animation: 'Home'},
      },
      {
        path: 'sar',
        loadChildren: () => import('./views/sar/sar.module').then(m => m.SarModule),
        data: { title: 'Purchase Requests', breadcrumb: 'PRs',animation: 'Home'}
      },
      {
        path: 'logistics',
        loadChildren: () => import('./views/logistics/logistics.module').then(m => m.LogisticsModule),
        data: { title: 'Logistics',animation: 'Home'}
      },
      {
        path: 'trading',
        loadChildren: () => import('./views/trading/trading.module').then(m => m.TradingModule),
        data: { title: 'Trading',animation: 'Home'}
      },
      {
        path: 'sales',
        loadChildren: () => import('./views/sales/sales.module').then(m => m.SalesModule),
        data: { title: 'Sales',animation: 'Home'}
      },
      {
        path: 'agenda',
        loadChildren: () => import('./views/sar/sar.module').then(m => m.SarModule),
        data: { title: 'Agenda', breadcrumb: 'Session Agenda',animation: 'Home'}
      },
      {
        path: 'user',
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule),
        data: { title: 'Users', breadcrumb: 'Users',animation: 'Home'}
      },
      {
        path: 'structure',
        loadChildren: () => import('./views/workflows/workflows.module').then(m => m.WorkflowsModule),
        data: { title: 'Approval flows', breadcrumb: 'Approval flows',animation: 'Home'}
      },
      {
        path: 'purchase-orders',
        loadChildren: () => import('./views/purchase-orders/purchase-orders.module').then(m => m.PurchaseOrdersModule),
        data: { title: 'Purchase orders', breadcrumb: 'Purchase orders',animation: 'Home'}
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
        data: { title: "Settings", breadcrumb: 'Settings', animation: 'Home'}
      },
      {
        path: 'forecast',
        loadChildren: () => import('./views/forecast/forecast.module').then(m => m.ForecastModule),
        data: { title: "Forecast", breadcrumb: 'Forecast', animation: 'Home'}
      },
      {
        path: 'refdata',
        loadChildren: () => import('./views/refdata/refdata.module').then(m => m.RefdataModule),
        data: { title: "Lists", breadcrumb: 'Lists', animation: 'Home'}
      }
    ]
  },
  {path:'rejecttest',component:ApprovalDecisionComponent}  ,
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

