<div mat-dialog-title>
    <mat-card-title style="font-size: 20px !important;" *ngIf="!data?.title && !this.data?.hideConfirmButton">{{'Warning'|translate}}</mat-card-title><span style="font-size: 20px !important;" *ngIf="this.data?.hideConfirmButton">Credit limit info</span>
    <mat-card-title style="font-size: 20px !important;" *ngIf="data?.title">{{data.title}}</mat-card-title>
</div>
<div mat-dialog-content style="height:auto !important">
    <!--
    <div style="width: 100%;">
        <mat-card-title *ngIf="data?.title">{{data.title}}</mat-card-title>
    </div>
    -->
    <kendo-label *ngIf="!data" text="Are you sure you want to cancel? All data will be lost."></kendo-label>
    <div *ngIf="data && !this.data?.hideConfirmButton">{{data.message| translate}}</div>
    <div *ngIf="data?.additionalCreditData" style="margin-top: 10px;">
        <div><b>{{data?.additionalCreditData?.businessPartner}} - {{data?.additionalCreditData?.businessPartnerName1}}</b></div>
        <div style="color: red; font-weight: 600;font-size: 16px;" *ngIf="this.delta <= 0">
            <mat-icon style="vertical-align: sub;">warning</mat-icon>
            <span>{{this.getCreditLimitValue()}}</span>
        </div>
        <div style="color: #45c345; font-weight: 600;font-size: 16px;" *ngIf="this.delta > 0">
            <mat-icon style="vertical-align: sub;">check</mat-icon>
            <span>{{this.getCreditLimitValue()}}</span>
        </div>
        <div>Total sales amount: {{data?.totalCost}} {{data?.currency}}</div>
        <div>Limit: {{data?.additionalCreditData?.creditLimitAmount}}</div>
        <div>Credit exposure: {{data?.additionalCreditData?.staticCreditExposureAmount}}</div>
        <div>Utilization (%): {{data?.additionalCreditData?.staticCreditExposurePercent}}</div>
    </div>
</div>


<mat-dialog-actions align="end">
    <button mat-raised-button *ngIf="!this.data?.hideConfirmButton" color="primary" class="button-primary" (click)="closeDialog(true)">{{'Yes' | translate }}</button>
    <button mat-raised-button *ngIf="!this.data?.hideConfirmButton" color="warn" (click)="closeDialog(false)">{{'No' | translate }}</button>
    <button mat-raised-button *ngIf="this.data?.hideConfirmButton" color="warn" (click)="closeDialog(false)">{{'Close' | translate }}</button>
</mat-dialog-actions>