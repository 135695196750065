import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterService } from "@progress/kendo-angular-grid";
import { distinct, filterBy, FilterDescriptor } from "@progress/kendo-data-query";

/**
 * Component that implements a custom filter for kendo grid filter feature;
 * it only displays filter options based on the distinct values that
 * the column field contains at the moment
 */
@Component({
    selector: 'app-custom-filter',
    templateUrl: './custom-filter.component.html',
    styleUrls: [
      './custom-filter.component.css']
  })

export class CustomFilterComponent implements AfterViewInit {
  @Input() public isPrimitive: boolean;
  @Input() public currentFilter: any;
  @Input() public data;
  @Input() public textField;
  @Input() public valueField;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Output() public valueChange = new EventEmitter<number[]>();

  public currentData: any;
  public showFilter = true;
  private value: any[] = [];

  protected textAccessor = (dataItem: any) =>
    this.isPrimitive ? dataItem : dataItem[this.textField];
  protected valueAccessor = (dataItem: any) =>
    this.isPrimitive ? dataItem : dataItem[this.valueField];

  ngAfterViewInit(){
    this.data.forEach(item => {
      if(item == "" || item == undefined || item == null){
        item = "(blank)"
      }
    })
    if(this.data != null){
      this.currentData = this.data;
      this.currentData.sort();
      for(var i = 0; i < this.currentData.length; i++){
        if(this.currentData[i] < 0){
          this.currentData.splice(i, 1);
        }
        if(this.currentData[i] == "" || this.currentData[i] == undefined || this.currentData[i] == null){
          this.currentData[i] = "(blank)";
        }
      }
      this.value = this.currentFilter.filters?.map((f: FilterDescriptor) => f.value);

      this.filters = this.value.map((value) => ({
        field: this.field,
        operator: "eq",
        value,
      }));
      this.filters.forEach(filter => {
        if(filter.value == ""){
          filter.operator = "isempty"
        }
      })
      this.showFilter = typeof this.textAccessor(this.currentData[0]) === "string";
    }
  }

  constructor(){}

  public isItemSelected(item) {
    if(item == "(blank)"){
      return this.value.some((x) => x === this.valueAccessor(""));
    }else{
      return this.value.some((x) => x === this.valueAccessor(item));
    }
  }
    
  public filters: any[] = [];
  public onSelectionChange(item, li) {
    if(item == "(blank)"){
      item = "";
    }
    if (this.value.some((x) => x === item)) {
      this.value = this.value.filter((x) => x !== item);
      for(var i = 0; i < this.filters.length; i++){
        if(this.filters[i].value == item){
          this.filters.splice(i, 1);
        }
      }
    }else {
      if(item == ""){
        this.filters.push({
          field: this.field,
          operator: "isempty",
          value: item
        });
      }else{
        this.value.push(item);
        this.filters.push({
          field: this.field,
          operator: "eq",
          value: item
        });
      }
    }
    this.filterService.filter({
      filters: this.filters,
      logic: "or"
    });
    this.onFocus(li);
  }

  public onInput(e: any) {
    this.currentData = distinct(
      [
      ...this.currentData.filter((dataItem) =>
          this.value.some((val) => val === this.valueAccessor(dataItem))
      ),
      ...filterBy(this.data, {
          operator: "contains",
          field: this.textField,
          value: e.target.value,
      }),
      ],
      this.textField
    );
  
  }

  public onFocus(li: any): void {
    const ul = li.parentNode;
    const below =
      ul.scrollTop + ul.offsetHeight < li.offsetTop + li.offsetHeight;
    const above = li.offsetTop < ul.scrollTop;
    // Scroll to focused checkbox
    if (below || above) {
      ul.scrollTop = li.offsetTop;
    }
  }

}