import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from 'src/app/app-config.module';
import { IAppModuleModel, IAppSettingsModel } from 'src/app/views/refdata/models/i-app-settings.model';
import {Observable} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpUtils } from 'src/app/views/core/utils/http.utils';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private readonly appSettingsKey: string = 'App-Settings';
  public appConfigs: any[] = [];
  

  constructor(private translate: TranslateService, private http: HttpClient, private config: AppConfig) {
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    if (appSettings && appSettings.locale){
      this.translate.setDefaultLang(appSettings.locale);
      this.translate.use(appSettings.locale);
    }
  }

  public setAppSettings(appSettings: IAppSettingsModel){
    localStorage.setItem(this.appSettingsKey, JSON.stringify(appSettings));
    if (appSettings && appSettings.locale){
      this.translate.setDefaultLang(appSettings.locale);
      this.translate.use(appSettings.locale);
    }
  }

  public getAppSettings(): IAppSettingsModel{
    const appSettingsString = localStorage.getItem(this.appSettingsKey);
    return JSON.parse(appSettingsString) as IAppSettingsModel;
  }

  public getAppCurrencyCode(): string{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings.currencyCode;
  }

  public getAppLocale(): string{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings.locale;
  }

  public getAvailableModules(): IAppModuleModel[]{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings?.modules;
  }

  public getPlantsSingleMode(): boolean{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings.plantsSingleMode;
  }

  public getStorageLocationsSingleMode(): boolean{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings.storageLocationsSingleMode;
  }

  public getFormConfigs(): any[]{
    const appSettings: IAppSettingsModel = JSON.parse(localStorage.getItem(this.appSettingsKey));
    return appSettings?.modules.map(x => x.settings?.formConfigs);
    // return [
    //   {
    //     optionalGroup: {
    //       costCentre: false,
    //       eProj: false,
    //       par: false,
    //       parLineItem: false,
    //       itemDescriptionPartNumber: false
    //     }
    //   },
    // ]
  }

  //TODO: store in local storage???
  public setAppConfigs(appSettings: any[]): void{
    this.appConfigs = appSettings;
  }

  public getAppConfigs(){ return this.appConfigs}

  getAppConfigurations<T>(): Observable<T> {
    const url = this.config.getConfig('apiEndpoint') + 'app/settings';
    // const url = this.config.getConfig('apiEndpoint') + 'appsettings/1';
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  getNonSystemAppSettings<T>(): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'appsetting/nonSystem';
    return this.http.get<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  updateAppSetting<T>(settingUpdate: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'appsetting';
    return this.http.patch<T>(url, settingUpdate).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }

  deleteAppSetting<T>(settingName: any): Observable<T>{
    const url = this.config.getConfig('apiEndpoint') + 'appsetting/' + settingName ;
    return this.http.delete<T>(url).pipe(
      catchError(HttpUtils.handleHttpClientError)
    );
  }
  
}
