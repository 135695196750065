import {Component, OnInit, EventEmitter, Input, Output, Renderer2} from '@angular/core';
import {ThemeService} from '../../services/theme.service';
import {LayoutService} from '../../services/layout.service';
import {TranslateService} from '@ngx-translate/core';
import {AuthGuard} from './../../../shared/services/auth/auth.guard';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromSar from '../../../views/sar/state';
import * as userActions from '../../../views/user/state/user.actions';
import { NavigationService } from '../../services/navigation.service';
import {Location} from '@angular/common';
import { AppService } from '../../services/app.service';
import { IAppSettingsModel } from 'src/app/views/refdata/models/i-app-settings.model';
import { TopbarService } from 'src/app/shared/services/topbar.service';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() notificPanel;
  currentLang = 'en';
  public availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }];
  public egretThemes;
  public layoutConf: any;
  routerEventSub: Subscription;
  visible: boolean = true;
  appSettings: IAppSettingsModel = {};
  title: string = '';

  constructor(
    private _location: Location,
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private authGuard: AuthGuard,
    public router: Router,
    private store: Store<fromSar.State>,
    private route :ActivatedRoute,
    public navService : NavigationService,
    public appService: AppService,
    public topbar: TopbarService
  ) {
    this.routerEventSub = this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((routeChange) => {
      this.visible =  this.router.url == '/agenda/sourcing-session-agenda' || 
                      this.router.url == '/sar/sar-dashboard' || 
                      this.router.url == '/user/user-dashboard' || this.router.url.includes("trading");
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => {
        let child = this.route.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data && child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          } else {
            return null;
          }
        }
        return null;
      })).subscribe( (title: string) => {
        this.title = title;
     });
  }

  ngOnInit() { 
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    this.translate.use(this.currentLang);
    this.appSettings = this.appService.getAppSettings();
    if(this.appSettings.name == "Arpadis Trading Portal"){
      this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }

    if(!this.navService.selectedItem || this.navService.selectedItem == "teste"){
      //this.toggleSidenav();
    }
  }

  ngOnDestroy() {
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
  }

  getName(){
    return this.authGuard.getProperty("name");
  }
  getPhotoUrl(){
    let photo = this.authGuard.getProperty("avatarUrl");
    if(photo == null || photo == "") photo = "assets/images/person.png";
    return photo;
  }
  navigateToProfile(){
    let userId = this.authGuard.getProperty('id');
    this.store.dispatch(new userActions.SelectedUserId(userId));
    this.store.dispatch(new userActions.GetUser(userId));
    this.router.navigate(['/user/user-details',userId]);
  }
  signout(){
    this.store.dispatch(new userActions.Logout());
    // this.router.navigate(['/sessions/signin']);
  }

  setLang(e) {
    this.translate.use(this.currentLang);
  }

  changeTheme(theme) {
    this.themeService.changeTheme(this.renderer, theme);
  }

  toggleNotific() {
    this.notificPanel.toggle();
  }

  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      });
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    });
  }

  toggleBack() {
    this._location.back();
  }
  // toggleCollapse() {
  //   // compact --> full
  //   if (this.layoutConf.sidebarStyle === 'compact') {
  //     return this.layout.publishLayoutChange({
  //       sidebarStyle: 'full'
  //     }, {transitionClass: true});
  //   }

  //   // * --> compact
  //   this.layout.publishLayoutChange({
  //     sidebarStyle: 'compact'
  //   }, {transitionClass: true});

  // }

  getActiveRoute(){
    //console.log("route",this.router.url)
    if (!this.title){
      this.title = this.route.firstChild.snapshot.data['title'];
    }
    if(this.router.url == '/structure/list'){
      this.title = 'Department';
    }
    if(this.router.url == '/sar/validate-sar'){
      this.title = '';
    }
    if(this.title == 'Forecast'){
      if(this.router.url){
        var array = this.router.url.split('/');
        if(array.length == 2){
          this.title = 'Forecast Overview'
        }
        if(array.length == 3){
          if(array[2] == 'submit'){
            this.title = 'Current Forecast'
          }
          if(array[2] == 'scenarios'){
            this.title = 'Forecast Scenarios'
          }
        }
      }
       // = '/forecast/scenarios'
    }
    if(this.router.url == '/purchase-orders/list-po'){
      this.title = this.navService.selectedItem;
    }
    if(this.router.url == '/trading/myWork'){
      this.title = 'My Work';
    }
    /*
    if(this.router.url == '/trading/dashboard'){
      this.title = 'My Trades';
    }
    */
   if(this.router.url.includes("trading") && this.router.url.includes("dashboard") && !this.router.url.includes("mywork")){
     this.title = "My Trades";
   }

   if(this.router.url.includes("trading") && this.router.url.includes("dashboard") && this.router.url.includes("mywork")){
    this.title = "My Work";
  }

    if(this.router.url.includes('/trading/overview')){
      this.title = "All Trades";
    }
    return this.title;  
  }

  getAppName():string{
    return this.topbar.getAppName();
  }
  getContext():any[]{
    console.log()
    return this.topbar.getContext();
  }
}
